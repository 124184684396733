import React, { useState, useRef } from "react"
import { HiOutlineUserAdd } from "react-icons/hi"
import { Icon } from "_components/global/icon"
import { NewCookieExpiryDate } from "_data/global_functions.js"
import { motion, useSpring } from "framer-motion"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { useOutsideClick } from "react-handle-outside-click"

export function UserName(
  props,
  currentName,
  set_name,
  set_cookies,
  Button,
  ModalWrapper,
  Modal,
  CopyWrapper,
  Copy,
  HeaderToolTip
) {
  const [showUsernameInput, set_showUsernameInput] = useState(false)
  const [showUsernameToolTip, set_showUsernameToolTip] = useState(false)
  const ref2 = useRef()
  const { register, handleSubmit } = useForm({})
  const usernameToolTip_opacity = useSpring()

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  function FormSubmit(data) {
    set_name(data.userInput)
    set_cookies("therapistName", data.userInput, {
      path: "/",
      expires: NewCookieExpiryDate(360),
    })
    set_showUsernameInput(false)
  }

  function OnClick() {}

  function handleOutsideClick() {
    set_showUsernameInput(false)
  }
  useOutsideClick(ref2, handleOutsideClick)

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <motion.div
        onClick={() => {
          set_showUsernameInput(!showUsernameInput)
        }}
        onHoverStart={() => {
          set_showUsernameToolTip(true)
          usernameToolTip_opacity.set(1)
        }}
        onHoverEnd={() => {
          set_showUsernameToolTip(false)
          usernameToolTip_opacity.set(0)
        }}
        style={{ cursor: "pointer", color: "grey" }}
      >
        {Icon(HiOutlineUserAdd, OnClick, "17px")}
      </motion.div>

      <HeaderToolTip
        style={{
          opacity: usernameToolTip_opacity,
          display: !showUsernameToolTip ? "none" : "",
          right: "0px",
        }}
      >
        Set Identity
      </HeaderToolTip>

      {showUsernameInput && (
        <ModalWrapper
          style={{
            top: props.smallScreen
              ? props.headerHeight_small
              : props.headerHeight_large,
          }}
          ref={ref2}
        >
          <Modal style={{ backgroundColor: props.primaryAppColor }}>
            <CopyWrapper>
              <Copy>
                Enter an optional name so that we can use it for client
                notifications.
              </Copy>
            </CopyWrapper>
            <FormWrapper>
              <Form onSubmit={handleSubmit(FormSubmit)}>
                <FormInput
                  name="userInput"
                  type="text"
                  defaultValue={currentName}
                  ref={register}
                />
                <Button
                  style={{
                    perspective: "500px",
                    backgroundColor: "#f16c42",
                  }}
                  initial={{ rotate: 0.01, scale: 1.01 }}
                  transition={spring}
                  whileHover={{ scale: 1.05, rotate: 0.02 }}
                  whileTap={{ scale: 0.99 }}
                >
                  Submit
                </Button>
              </Form>
            </FormWrapper>
          </Modal>
        </ModalWrapper>
      )}
    </motion.div>
  )
}

const FormWrapper = styled.div`
  flex: 1 1 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  margin: 0;
  padding: 0;
`

const FormInput = styled.input`
  height: 35px;
  border: none;
  outline: none;
  border-radius: 7px;
  color: white;
  font-weight: 500;
  font-size: 0.8em;
  background-color: #2196f3;
  padding: 0 10px 0 10px;
`

import React, { useState, useEffect, useContext, useMemo } from "react"
import { FiVolume2 } from "react-icons/fi"
import { FiVolumeX } from "react-icons/fi"
import { Icon } from "_components/global/icon"
import { motion, AnimatePresence } from "framer-motion"
// import { useCookies } from "react-cookie"
import { Cookies } from "react-cookie"
import { SocketContext } from "_app/common/SocketServer"
import styled from "styled-components"

const ClientVolume = () => {
  const [isMuted, set_isMuted] = useState(false)
  const [hintText, set_hintText] = useState("")
  const [showHint, set_showHint] = useState(false)
  // const [cookies, set_cookies] = useCookies(["sessionName"])
  const cookie = useMemo(() => {
    return new Cookies()
  }, [])
  const socket = useContext(SocketContext)

  useEffect(() => {
    socket.emit("audioMute", { sessionName: cookie.get("sessionName"), val: isMuted })
    console.log("cookie: " + cookie.get("sessionName"))
  }, [isMuted, socket, cookie])

  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("requestBallSize", () => {
        if (!didCancel) {
          socket.emit("audioMute", {
            sessionName: cookie.get("sessionName"),
            val: isMuted,
          })
        }
      })
    }

    return () => {
      didCancel = true
    }
  }, [cookie, socket, isMuted])

  function OnClick() {
    if (!isMuted) {
      set_hintText("Client audio disabled")
      set_showHint(true)
      set_isMuted(true)
    } else {
      set_hintText("Client audio enabled")
      set_showHint(true)
      set_isMuted(false)
    }
  }

  function OnComplete() {
    set_showHint(false)
  }

  return (
    <Content>
      <AnimatePresence>
        {!isMuted && <>{Icon(FiVolume2, OnClick, "29px", 0.65)}</>}
        {isMuted && <>{Icon(FiVolumeX, OnClick, "29px")}</>}
      </AnimatePresence>

      {showHint && (
        <AnimatePresence>
          <Hint
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0, 1, 1, 1, 0], y: [-65, -75] }}
            transition={{ duration: 2 }}
            onAnimationComplete={OnComplete}
          >
            {hintText}
          </Hint>
        </AnimatePresence>
      )}
    </Content>
  )
}

const Content = styled.div``

const Hint = styled(motion.div)`
  position: absolute;
  z-index: 20000;
  width: 160px;
  background-color: #d1dce6;
  text-align: center;
  margin-left: -68px;
  color: #245eab;
  padding: 5px 0 5px 0;
  border-radius: 6px;
  font-size: 12px;
  font-family: montserrat;
  font-weight: 600;
`

export default ClientVolume

import React, { useState, useEffect } from "react"
// import { between } from "polished"
import { FormLabel, FormInput, FormMessage } from "_data/global_styles"
import { color_formLabel } from "_src/assets/data/global_colors"
// import { Logger } from "_data/global_functions.js"
import { useForm } from "react-hubspot"
// import { useMitt } from "react-mitt"
import Button from "_components/buttons/button_button1"
import Modal from "_components/misc/modal_alert"
import styled from "styled-components"

// * Try using react/react-dom 16.14.0.  Remove current before adding.

const NewForm = (props) => {
  // const { emitter } = useMitt()
  // const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailModal, setShowFailModal] = useState(false)
  const [buttonText, setButtonText] = useState("Submit")

  const { isLoading, isError, handleSubmit } = useForm({
    portalId: "7430301",
    formId: "a9fda404-2585-4d7c-9f8e-83ec3f8a778d",
  })

  // const onSubmit = (data) => {
  //   console.log(data)
  // }

  // const submitForm = (e) => {
  //   e.preventDefault()
  //   handleSubmit(e)
  // }

  useEffect(
    (data) => {
      if (isError) {
        setShowSuccessModal(false)
        setShowFailModal(true)
      }
    },
    [isError]
  )

  useEffect(
    (data) => {
      if (isLoading) {
        setButtonText("Thank you!")
        if (props.skipHeader) {
          // This was here  when we removed menuSelect from app4.  
          // this may need to be re-worked.  Needs testing.
          // emitter.emit("menuSelect", {
          //   menu: "parent",
          //   section: 1,
          //   itemNum: 100,
          // })
        } else setShowSuccessModal(true)
      }
    },
    [isLoading, props.skipHeader]
  )

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * First Name:{" "}
              </FormLabel>
              <FormInput name="firstname" type="text" required />
            </InputContainer>
          </Column>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * Last Name:{" "}
              </FormLabel>
              <FormInput name="lastname" type="text" required />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                Phone:{" "}
              </FormLabel>
              <FormInput name="phone" type="text" />
            </InputContainer>
          </Column>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * Email:{" "}
              </FormLabel>
              <FormInput name="email" type="email" required />
            </InputContainer>
          </Column>
        </Row>
        <MessageRow>
          <MessageContainer>
            <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
              * Message:{" "}
            </FormLabel>
            <FormMessage name="single_line" type="text" required />
          </MessageContainer>
        </MessageRow>
        <Row>
          <Column></Column>
          <ButtonContainer>
            <Button isForm="true" copy={buttonText} />
          </ButtonContainer>
        </Row>
      </Form>

      {showSuccessModal === true && (
        <Modal
          return="/"
          title="Message Received"
          buttonMessage="Return to Homepage"
          message="We will get back to you soon."
        />
      )}

      {showFailModal === true && (
        <Modal
          return="this"
          title="Ohhh nooooo!!!"
          buttonMessage="Close and Try Again"
          message="There was a problem with one or more of your entries.  Please check that you correctly typed in your email address."
        />
      )}
    </>
  )
}

export default NewForm

const Form = styled.form`
  font-family: montserrat;
  font-weight: 600;
  font-size: 14px;
`
// const Error = styled.span`
//   color: #d20000;
// `
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 280px) {
    flex-direction: column;
  }
`
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const InputContainer = styled.div`
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
const ButtonContainer = styled.div`
  width: 40%;
  margin-top: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (max-width: 500px) {
    align-items: center;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`
const MessageContainer = styled.div`
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 280px) {
    width: 90%;
  }
`

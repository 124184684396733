import React, { useState, useEffect, useRef, useContext } from "react"
import { GiResize } from "react-icons/gi"
import { Icon } from "_components/global/icon"
import { NewCookieExpiryDate } from "_data/global_functions.js"
import { ScaleRange } from "_data/global_functions"
import { useCookies } from "react-cookie"
import { useMitt } from "react-mitt"
import Slider from "./Slider"
import { SocketContext } from "_app/common/SocketServer"

const BallSize = (props) => {
  const [cookies, set_cookies] = useCookies(["sizeVis", "size"])
  const [showSlider, setShowSlider] = useState(false)
  const { emitter } = useMitt()
  const initialValue = useRef(cookies.sizeVis)
  const prevValue = useRef(0)
  const socket = useContext(SocketContext)

  useEffect(() => {
    if (props.showSizeSlider) setShowSlider(true)
  }, [props.showSizeSlider])

  function AdjustBallSize() {
    props.CB_showSlider("size")
  }

  function OnCallback(isFinal, controlOutput) {
    const adjValue = ScaleRange(controlOutput, 1, 100, 0.2, 4).toPrecision(2)

    if (prevValue.current !== adjValue) {
      emitter.emit("ballSize", { size: adjValue })
      prevValue.current = adjValue
    }

    if (isFinal) {
      socket.emit("confirmedSize", {
        size: adjValue,
        sessionName: cookies.sessionName,
      })

      set_cookies("sizeVis", controlOutput, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })

      set_cookies("size", adjValue, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })

      setShowSlider(false)
      props.set_showSizeSlider(false)
      props.set_showSingleControl(false)
    }
  }

  return (
    <>
      {!showSlider && <>{Icon(GiResize, AdjustBallSize, "21px", 0.6)}</>}

      {showSlider && (
        <>
          <Slider
            label="Size"
            min={1}
            max={100}
            initialValue={initialValue.current}
            callback={OnCallback}
          />
        </>
      )}
    </>
  )
}

export default BallSize

import { useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import { SocketContext } from "_app/common/SocketServer"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { SetCookie } from "_app/common/utils/SetCookie"
import { Logger } from "_data/global_functions.js"
// import { version } from "socket.io-client/package.json"

const TherapistSocket = () => {
  const socket = useContext(SocketContext)
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "size",
    "sessionName",
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "provider",
    "integratedProvider",
  ])
  const setCookie = SetCookie(set_cookie)

  // :s: Check for server availability.
  useEffect(() => {
    if (socket) {
      // console.log("We have a socket in TherapistSocket.... " + socket.connected)
      // console.log(version)
      g.set_servAvailable(socket.connected)
    }
  }, [socket, g])

  // :s: Recognize server connects and disconnects.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("connect", () => {
        if (!didCancel) g.set_servAvailable(true)
      })

      socket.on("disconnect", function () {
        if (!didCancel) g.set_servAvailable(false)
      })

      socket.on("userDisconnect", () => {
        if (!didCancel) g.set_clientConnected(false)
      })

      socket.on("endSession", function () {
        if (!didCancel) window.location.pathname = "/therapist"
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g])

  // :s: Server acks connection requests.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("serverAck", (data) => {
        if (!didCancel) {
          if (data.to === "therapist") {
            setCookie("user", "therapist")
            g.set_servConnected(true)
          }
          if (data.to === "client") {
            g.set_clientConnected(true)

            if (!g.vidWinOpen) {
              /*
                Send shortened socket.emit that will trigger client to enter app.
                Sending complete videoSettings is unnessecary and a waste of 
                bandwidth.
              */
              socket.emit("videoSettings", {
                sessionName: data.sessionName,
                clientUrl: "noVideo",
              })
            }
          }
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [
    cookie.sessionName,
    cookie._conn_clientUrl,
    cookie.integratedProvider,
    cookie.size,
    socket,
    g,
    setCookie,
  ])

  // :s: Reconnect therapist to server when server goes from unavailable to available.
  // --> Added from old Socket on 3/8
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("connect", () => {
        if (!didCancel) {
          if (g.servConnected) {
            socket.emit("join", {
              sessionName: cookie.sessionName,
              from: "therapist",
            })
          }
          g.set_servAvailable(true)
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g.servConnected, cookie.sessionName, g])

  // :s: Respond to client socket ready
  // --> If client arrives before therapist, client will send ready when
  // --> it serverAck to therapist.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("clientReady", () => {
        if (!didCancel) g.set_clientConnected(true)
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g])

  // :s: React to g.showPage getting toggleVideo value
  useEffect(() => {
    if (g.showPage === "toggleVideo") {
      if (g.providerChosen) {
        // called only with connector selected.
        // Logger("Show Video clicked.  g.providerChosen")
        g.set_vidWinOpen(true)
        if (g.therapistUrl !== "noVideo") {
          g.set_vidWinOpen(true)
          g.set_showPage("videoWarning")
        } else g.set_showPage("vidNotReady")
      } else g.set_showPage("vidNotReady")
    }
  }, [g])

  // :s: When therapist opens window, send video settings to client.
  useEffect(() => {
    if (g.vidWinOpen) {
      if (g.integratedProvider) g.set_leftMenuVidText("Video Conference Active")
      if (g.clientConnected) {
        Logger("---------- sending socket videoSettings ---------")
        Logger("sessionName: " + cookie.sessionName)
        Logger("clientUrl: " + cookie._conn_clientUrl)
        Logger("integratedProvider: " + cookie.integratedProvider)
        Logger("g.vidWinOpen: " + g.vidWinOpen)
        Logger("ballSize: " + cookie.size)
        Logger("-------------------------------------------------")

        socket.emit("videoSettings", {
          sessionName: cookie.sessionName,
          clientUrl: cookie._conn_clientUrl,
          integratedProvider: cookie.integratedProvider,
          therapistVidOpen: g.vidWinOpen,
          ballSize: cookie.size,
        })
      }
    }
  }, [
    cookie.sessionName,
    socket,
    cookie._conn_clientUrl,
    cookie.integratedProvider,
    cookie.size,
    g,
  ])

  // :s: Catch when therapist selects No Video connector.
  useEffect(() => {
    if (g.clientConnected) {
      if (g.showPage === "ProviderConfirmed" && g.clientUrl === "noVideo") {
        socket.emit("videoSettings", {
          sessionName: cookie.sessionName,
          clientUrl: "noVideo",
        })
      }
    }
  }, [g, cookie.sessionName, socket])

  // :s: Client requested ball size.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("requestBallSize", () => {
        if (!didCancel) {
          socket.emit("confirmedSize", {
            size: cookie.size,
            sessionName: cookie.sessionName,
          })
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [cookie.size, cookie.sessionName, socket])

  // :s: Send videoSettings to client
  // --> Moved back here from separate file.
  // function SendVideoSettingsToClient() {
  //   Logger("---------- sending socket videoSettings ---------")
  //   Logger("sessionName: " + cookie.sessionName)
  //   Logger("clientUrl: " + cookie._conn_clientUrl)
  //   Logger("integratedProvider: " + cookie.integratedProvider)
  //   Logger("g.vidWinOpen: " + g.vidWinOpen)
  //   Logger("ballSize: " + cookie.size)
  //   Logger("-------------------------------------------------")

  //   socket.emit("videoSettings", {
  //     sessionName: cookie.sessionName,
  //     clientUrl: cookie._conn_clientUrl,
  //     integratedProvider: cookie.integratedProvider,
  //     therapistVidOpen: g.vidWinOpen,
  //     ballSize: cookie.size,
  //   })
  // }

  return null
}

export default TherapistSocket

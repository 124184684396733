import React from "react"
import styled from "styled-components"
import { Wrapper, Title, Highlight, Answer, ParagraphGroup } from "./styles"

const Welcome = () => {
  return (
    <Wrapper>
      <div style={{ maxWidth: "650px" }}>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Videos to help you get going.</Title>
          <ParagraphGroup>
            <Answer>
              The videos below are only a few days old, but with recent changes
              to our app, they contain out of date information. We plan on
              releasing an updated video soon. Please join our{" "}
              <a
                href="https://www.youtube.com/channel/UCcbAmJDgC_GxU0Pombs1iNA"
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Highlight>YouTube Channel</Highlight>
              </a>{" "}
              to be notified when new content is released.
            </Answer>
          </ParagraphGroup>
        </header>
        <VideoWrapper style={{ marginTop: "40px" }}>
          <Video
            initial={{ y: 20, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.85, ease: "easeOut" }}
          >
            <iframe
              title="vid"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/74UvWk39_Zo"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Video>
        </VideoWrapper>

        <VideoWrapper style={{ marginTop: "40px", marginBottom: "50px" }}>
          <Video
            initial={{ y: 20, opacity: 0, rotate: 0.01 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.85, ease: "easeOut" }}
          >
            <iframe
              title="vid"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/5YHVw_E5TiY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Video>
        </VideoWrapper>
      </div>
    </Wrapper>
  )
}

const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Video = styled.div`
  height: 270px;
  width: 475px;
  @media only screen and (max-width: 800px) {
    height: 230px;
    width: 400px;
  }
  @media only screen and (max-width: 550px) {
    width: 250px;
    height: 140px;
  }
  /* @media only screen and (max-width: 900px) {
    margin-top: 5vh;
    order: 2;
    width: 70vw;
    height: 38vw;
  } */
  /* width: 100%;
  height: 100%; */
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.65),
    0 10px 10px -5px rgba(50, 50, 73, 0.5);
`

export default Welcome

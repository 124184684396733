import React, { createContext, useState } from "react"

export const TherapistContext = createContext()

export const UserProvider = ({ children }) => {
  const [sessionName, set_sessionName] = useState("")
  const [vidReady, set_vidReady] = useState(false)
  const [showVideo, set_showVideo] = useState(false)
  const [leftMenuVidText, set_leftMenuVidText] = useState("")
  const [therapistUrl, set_therapistUrl] = useState("")
  const [clientConnected, set_clientConnected] = useState(false)
  const [serverAvailable, set_serverAvailable] = useState()

  // New vars as of 2/23
  const [servAvailable, set_servAvailable] = useState()
  const [servConnected, set_servConnected] = useState(false)
  const [providerChosen, set_providerChosen] = useState(false)
  const [clientUrl, set_clientUrl] = useState(false)
  const [vidWinOpen, set_vidWinOpen] = useState(false)
  const [reconnAfterDisc, set_reconnAfterDisc] = useState(false)
  const [client_vidWinOpen, set_client_vidWinOpen] = useState(false)
  const [showPage, set_showPage] = useState("welcome")
  // const [integratedProvider, set_integratedProvider] = useState(false)

  return (
    <TherapistContext.Provider
      value={{
        sessionName,
        set_sessionName,
        vidReady,
        set_vidReady,
        showVideo,
        set_showVideo,
        leftMenuVidText,
        set_leftMenuVidText,
        therapistUrl,
        set_therapistUrl,
        clientConnected,
        set_clientConnected,
        serverAvailable,
        set_serverAvailable,

        servAvailable,
        set_servAvailable,
        servConnected,
        set_servConnected,
        providerChosen,
        set_providerChosen,
        clientUrl,
        set_clientUrl,
        vidWinOpen,
        set_vidWinOpen,
        reconnAfterDisc,
        set_reconnAfterDisc,
        client_vidWinOpen,
        set_client_vidWinOpen,
        showPage,
        set_showPage,
        // integratedProvider,
        // set_integratedProvider
      }}
    >
      {children}
    </TherapistContext.Provider>
  )
}

import React, { useState, useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { SetCookie } from "_app/common/utils/SetCookie"
import {
  Wrapper,
  Title,
  Form,
  FormRow,
  FormInput,
  Answer,
  Highlight,
  Link,
  Comment,
  ButtonType1,
} from "../styles"

const Doxy = () => {
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "_conn_doxyClinic",
    "_conn_doxyFreePro",
  ])
  const setCookie = SetCookie(set_cookie)
  const [clinicAccount, set_clinicAccount] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      userInput: cookie._conn_doxyFreePro,
      userInput2: cookie._conn_doxyClinic,
    },
  })

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  function SubmitForm(data) {
    if (clinicAccount) {
      const clientUrl = "https://" + data.userInput2 + ".doxy.me"
      const therapistUrl = clientUrl + "/sign-in"
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", clientUrl)
      setCookie("_conn_therapistUrl", therapistUrl)
      setCookie("_conn_doxyClinic", data.userInput2)
      setCookie("provider", "doxy")
      setCookie("integratedProvider", "true")
    }

    if (!clinicAccount) {
      const clientUrl = "https://doxy.me/" + data.userInput
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", clientUrl)
      setCookie("_conn_therapistUrl", "https://doxy.me/sign-in")
      setCookie("_conn_doxyFreePro", data.userInput)
      setCookie("provider", "doxy")
      setCookie("integratedProvider", "true")
    }
  }

  function ChangeAccountType() {
    set_clinicAccount(!clinicAccount)
  }

  return (
    <Wrapper>
      <div>
        {!clinicAccount && (
          <>
            <Title multiplier=".9">Doxy (Free/Professional)</Title>
            <Answer style={{ marginTop: "3px" }}>
              Doxy.me is embedded within our app for the best experience. Please
              enter your <Highlight>Free</Highlight> or{" "}
              <Highlight>Professional</Highlight> Doxy.me room name below. We do
              not verify entries, please check for accuracy before proceeding.
            </Answer>

            <Comment>
              If you are using a Doxy <Highlight>Clinic</Highlight> account
              instead of the Free or Professional version,{" "}
              <Link onClick={ChangeAccountType}>please click here</Link>.
            </Comment>
          </>
        )}

        {clinicAccount && (
          <>
            <Title multiplier=".9">Doxy (Clinic Account)</Title>
            <Answer style={{ marginTop: "3px" }}>
              Enter your clinic account name below. We do not verify entries,
              please check for accuracy before proceeding.
            </Answer>

            <Comment>
              If you are using a <Highlight>Free</Highlight> or{" "}
              <Highlight>Professional</Highlight> account,{" "}
              <Link onClick={ChangeAccountType}>please click here</Link>.
            </Comment>
          </>
        )}
      </div>

      <Form onSubmit={handleSubmit(SubmitForm)}>
        <FormRow>
          {!clinicAccount && (
            <FormInput name="userInput" type="text" ref={register} required />
          )}
          {clinicAccount && (
            <FormInput name="userInput2" type="text" ref={register} required />
          )}
          <ButtonType1
            style={{
              perspective: "500px",
            }}
            initial={{ rotate: 0.01, scale: 1.01 }}
            transition={spring}
            whileHover={{
              scale: 1.05,
              rotate: 0.02,
              backgroundColor: "#984FB8",
            }}
            whileTap={{ scale: 0.99 }}
          >
            {clinicAccount && <span>Set Clinic Room Name</span>}
            {!clinicAccount && <span>Set Room Name</span>}
          </ButtonType1>
        </FormRow>
      </Form>
    </Wrapper>
  )
}

export default Doxy

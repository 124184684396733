import React from "react"
import ContactForm from "_components/forms/form_contact"
import { Wrapper, Title, Answer } from "./styles"

const Support = (props) => {
  return (
    <Wrapper>
      <div>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Contact Support</Title>

          <Answer>
            Please fill out the form below. We try our best to get back to you
            within a business day.
          </Answer>
        </header>

        <div style={{ marginLeft: "10px" }}>
          <ContactForm skipHeader={props.skipHeader} />
        </div>
      </div>
    </Wrapper>
  )
}

export default Support

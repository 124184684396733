import React, { useContext } from "react"
import AnyMeeting from "_app/therapist/pages/providers/AnyMeeting"
import AppFaq from "_app/therapist/pages/Faq"
import Emdr from "_app/therapist/emdr/Emdr"
import ChangeLog from "_app/therapist/pages/Changelog"
import Doxy from "_app/therapist/pages/providers/Doxy"
import EndSession from "_app/therapist/pages/EndSession"
import Generic from "_app/therapist/pages/providers/Generic"
import Google from "_app/therapist/pages/providers/Google"
import ProviderConfirmed from "_app/therapist/pages/ProviderConfirmed"
import QuickStart from "_app/therapist/pages/QuickStart"
import Videos from "_app/therapist/pages/Videos"
import SelectProvider from "_app/therapist/pages/providers/Providers"
import SimplePractice from "_app/therapist/pages/providers/SimplePractice"
import Support from "_app/therapist/pages/Support"
import VideoNotReady from "_app/therapist/pages/VideoNotReady"
import Vsee from "_app/therapist/pages/providers/Vsee"
import WelcomeMessage from "_app/therapist/pages/Welcome"
import Zoom from "_app/therapist/pages/providers/Zoom"
import VideoWindowOpened from "_app/therapist/pages/VideoWindowOpened"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
//import { useCookies } from "react-cookie"

const ShowPage = () => {
  const g = useContext(TherapistContext)
  //const [cookies, set_cookies] = useCookies(["provider"])

  return (
    <>
      {g.showPage === "welcome" && <WelcomeMessage />}
      {g.showPage === "emdr" && <Emdr />}
      {g.showPage === "faq" && <AppFaq />}
      {g.showPage === "changeLog" && <ChangeLog />}
      {g.showPage === "support" && <Support skipHeader="true" />}
      {g.showPage === "selectProvider" && <SelectProvider />}
      {g.showPage === "quickStart" && <QuickStart />}
      {g.showPage === "videos" && <Videos />}
      {g.showPage === "ProviderConfirmed" && (
        <ProviderConfirmed clientUrl={g.clientUrl} />
      )}
      {g.showPage === "endSession" && (
        <EndSession sessionName={g.sessionName} />
      )}
      {g.showPage === "vidNotReady" && <VideoNotReady />}
      {g.showPage === "Doxy" && <Doxy />}
      {g.showPage === "Zoom" && <Zoom />}
      {g.showPage === "Google" && <Google />}
      {g.showPage === "AnyMeeting" && <AnyMeeting />}
      {g.showPage === "VSee" && <Vsee />}
      {g.showPage === "SimplePractice" && <SimplePractice />}
      {g.showPage === "Generic" && <Generic />}
      {g.showPage === "videoWarning" && (
        <VideoWindowOpened therapistUrl={g.therapistUrl} />
      )}
    </>
  )
}

export default ShowPage

import React, { useState, useEffect } from "react"
import { BsFillPersonFill } from "react-icons/bs"
import { motion, useSpring } from "framer-motion"

export function ClientStatus(
  clientConnected,
  servAvailable,
  color_disconnected,
  color_waiting,
  color_connected,
  HeaderIcon,
  HeaderToolTip
) {
  const [ready, set_ready] = useState(false)
  const [clientStatus, set_clientStatus] = useState(1)
  const [clientToolTip, set_clientToolTip] = useState("hello")
  const [showClientToolTip, set_showClientToolTip] = useState(false)
  const clientToolTip_opacity = useSpring()

  useEffect(() => {
    set_clientStatus(1)
    set_ready(true)
  }, [])

  useEffect(() => {
    if (clientStatus === 0) set_clientToolTip("Client not connected.")
    if (clientStatus === 1) set_clientToolTip("Waiting for client to connect.")
    if (clientStatus === 2) set_clientToolTip("Client Connected")
    if (clientStatus === 3)
      set_clientToolTip("Client not connected (Server Disconnect)")
  }, [clientStatus])

  useEffect(() => {
    if (ready) {
      if (clientConnected) set_clientStatus(2)
      if (!clientConnected) set_clientStatus(0)
      if (!servAvailable) set_clientStatus(3)
    }
  }, [clientConnected, servAvailable, ready])

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.8 }}
    >
      <HeaderIcon
        style={{
          color:
            clientStatus === 0
              ? color_disconnected
              : clientStatus === 1
              ? color_waiting
              : clientStatus === 3
              ? color_disconnected
              : color_connected,
          fontSize: "19px",
        }}
        onHoverStart={() => {
          set_showClientToolTip(true)
          clientToolTip_opacity.set(1)
        }}
        onHoverEnd={() => {
          set_showClientToolTip(false)
          clientToolTip_opacity.set(0)
        }}
      >
        <BsFillPersonFill />

        <HeaderToolTip
          style={{
            opacity: clientToolTip_opacity,
            display: !showClientToolTip ? "none" : "",
          }}
        >
          {clientToolTip}
        </HeaderToolTip>
      </HeaderIcon>
    </motion.div>
  )
}

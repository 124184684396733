import React, { useState, useContext } from "react"
import { isMobile } from "react-device-detect"
import { motion, useSpring } from "framer-motion"
import styled from "styled-components"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { Cookies } from "react-cookie"
import {
  hoverColor,
  naturalColor,
  hoverFontColor,
  naturalFontColor,
} from "./styles"

const RowItem = (props) => {
  const g = useContext(TherapistContext)
  const bgColor = useSpring(naturalColor)
  const fontColor = useSpring(naturalFontColor)
  const hintOpacity = useSpring()
  const itemOpacity = useSpring()
  const [showHint, set_showHint] = useState(false)
  const cookie = new Cookies()

  function OnTap(menu) {
    if (!props.smallScreen) {
      ExecuteMenuItem(menu)
    } else {
      if (isMobile) {
        ExecuteMenuItem(menu)
      } else {
        ExecuteMenuItem(menu)
      }
    }
  }

  function ExecuteMenuItem(menu) {
    if (menu === "toggleFullscreen") {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    }

    if (menu === "toggleMenuSize") props.set_smallScreen(!props.smallScreen)

    if (menu === "toggleVideo" && cookie.get("provider") === "doxy") {
      g.set_vidWinOpen(true)
    } else if (menu !== "toggleMenuSize") {
      g.set_showPage(menu)
    }
  }

  return (
    <>
      <Row
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0, duration: 1, ease: "easeOut" }}
        onTapStart={() => {
          if (!isMobile) {
            bgColor.set(hoverColor)
            fontColor.set(hoverFontColor)
            hintOpacity.set(1)
          }
        }}
        onHoverStart={() => {
          if (!isMobile) {
            set_showHint(true)
            bgColor.set(hoverColor)
            fontColor.set(hoverFontColor)
            hintOpacity.set(1)
          }
        }}
        onHoverEnd={() => {
          if (!isMobile) {
            set_showHint(false)
            bgColor.set(naturalColor)
            fontColor.set(naturalFontColor)
            hintOpacity.set(0)
          }
        }}
        onClick={() => {
          if (
            props.isWindowOpen &&
            g.providerChosen &&
            g.therapistUrl !== "noVideo" &&
            cookie.get("provider") !== "doxy"
          ) {
            // Because Safari will not open a new window unless it happens in
            // the onClick event, we are adding it here.  We will still emit
            // the toggleVideo emitter so that it is seen by other components.
            //
            window.open(
              g.therapistUrl,
              "activeEMDR_therapistWindow",
              "height=400,width=700,status=no, toolbar=no,menubar=no,location=no"
            )
          }

          OnTap(props.menu)
        }}
        style={{
          display: !isMobile ? "" : props.hideMobile ? "none" : "",
          backgroundColor: bgColor,
          color: fontColor,
          height: !props.smallScreen ? "32px" : "36px",
          opacity: itemOpacity,
        }}
      >
        <Inset
          style={{
            justifyContent: props.smallScreen ? "center" : "flex-start",
          }}
        >
          <MenuIcon
            style={{
              fontSize: !props.smallScreen ? "1.25em" : "1.25em",
            }}
          >
            {props.icon}
          </MenuIcon>
          {!props.smallScreen && <MenuItem>{props.label}</MenuItem>}
        </Inset>

        {props.smallScreen && hintOpacity !== 0 && (
          <Hint
            style={{
              display: !showHint ? "none" : "",
              left: "40px",
              opacity: hintOpacity,
            }}
          >
            {props.label}
          </Hint>
        )}
      </Row>
    </>
  )
}

const Row = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`

const Inset = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
`

const MenuIcon = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItem = styled(motion.div)`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Hint = styled(motion.div)`
  position: absolute;
  z-index: 10000;
  width: 220px;
  height: 32px;
  padding-left: 7px;
  font-size: 12px;
  background-color: ${hoverColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export default RowItem

import React from "react"
import { Wrapper, Title, Question, Answer } from "./styles"

const ChangeLog = () => {
  return (
    <Wrapper>
      <div>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Application Changelog</Title>
          <Answer>
            Meaningful application releases have been documented below. Software
            additions and changes prior to July 2020 have not been included.
          </Answer>
        </header>

        <div style={{ marginLeft: "10px" }}>
          <Question>05/11/2021 - v1.3</Question>

          <Answer>
            Added button (lower left menu) to therapist dashboard to toggle the
            size of the left menu. The size is still based on the therapist
            screensize, but can now be temporarily overridden by the therapist
            to expand/shrink the menu.
          </Answer>

          <Question>05/05/2021 - v1.2</Question>

          <Answer>
            Added button to client interface to emit an audio test tone. See FAQ
            for explanation and when/why it should be used.
          </Answer>

          <Question>04/17/2021 - v1.1</Question>

          <Answer>
            Removed the "old" version of ActiveEMDR as we have fully migrated to
            version 1.0.
          </Answer>

          <Question>03/24/2021 - v1.05</Question>
          <Answer>
            Fixed issue with some mobile devices being unable to see website
            homepage. This problem arrives with the last release on 3/18 and has
            been corrected.
          </Answer>
          <Question>03/18/2021 - v1.04</Question>
          <Answer>
            Maintenance: Switched to new session server and updated to latest
            software packages.
          </Answer>
          <Question>03/12/2021 - v1.033</Question>
          <Answer>
            The formula used to calculate ball RPM (repetitions per minute) had
            a precision error. The problem has been corrected and may require
            you to reset the ball speed.
          </Answer>

          <Question>03/11/2021 - v1.032</Question>
          <Answer>
            Fixed minor issue with client bls being positioned incorrectly with
            embedded Doxy window open. Also added icons to Doxy embedded window
            for resizing in smaller increments. Rearranged left menu items.
          </Answer>
          <Question>03/11/2021 - v1.031</Question>
          <Answer>
            Doxy video conferencing window can now be dragged around the screen.
          </Answer>
          <Question>03/10/2021 - v1.03</Question>
          <Answer>
            Updated copy all over the website, with an emphasis on less is more.
            Several workflow changes and improvements.
          </Answer>

          <Question>03/08/2021 - v1.02</Question>
          <Answer>
            Server optimizations, cleaned up Quick Start Guide and FAQ.
          </Answer>
          <Question>03/06/2021 - v1.01</Question>
          <Answer>
            Doxy.me is now integrated directly inside of the ActiveEMDR app.
          </Answer>
          <Question>03/05/2021 - v1.0</Question>
          <Answer>Version 1 has publicly released.</Answer>

          <Question>03/05/2021 - v1.0.b35</Question>
          <Answer>
            Several visual fixes for MacOS/Safari browsers. Added Tutorial
            Videos to left therapist dashboard menu.
          </Answer>

          <Question>02/26/2021 - v1.0.b27</Question>
          <Answer>
            More communication added between client/therapist to report video
            conferencing states if therapist switches to new video provider
            mid-session.
          </Answer>

          <Question>02/25/2021 - v1.0.b26</Question>
          <Answer>
            Client's upper right conferencing icon knows the therapist video
            state and reports that state to client. It can also be used to
            re-launch the client's video conferencing window.
          </Answer>
          <Question>02/22/2021 - v1.0.b25</Question>
          <Answer>
            MacOS and Safari browser now opens video conference windows
            properly.
          </Answer>

          <Question>02/22/2021 - v1.0.b24</Question>
          <Answer>
            Revert to previous version after bug discovered with Speed/Size
            changes. Problem corrected.
          </Answer>

          <Question>02/18/2021 - v1.0.b23</Question>
          <Answer>
            Therapist can now go straight to BLS without selecting video conf
            provider first.
          </Answer>

          <Question>02/18/2021 - v1.0.b22</Question>
          <Answer>
            New and old version working side by side as testing opened to more
            participants.
          </Answer>

          <Question>Jan/Feb of 2021 - v1.0.b05-21</Question>
          <Answer>
            Multiple releases as features implemented. Version 1.0 opened to
            limited participants.
          </Answer>

          <Question>12/21/2020 - v1.0.b02</Question>
          <Answer>
            The connection server has been re-written. Includes more robust
            error control, re-connection on loss, connection health polling, and
            more advanced client/server handshaking.
          </Answer>

          <Question>12/19/2020 - v1.0.b00</Question>
          <Answer>
            Version 1.0 is a complete re-write of ActiveEMDR with better support
            for video conferencing with a cleaner interface and better user
            experience. BLS is more optimized and saves settings between
            sessions.{" "}
          </Answer>

          <Question>12/18/2020 - v.91</Question>
          <Answer>
            Visual fixes for mobile devices. Any Meeting as embedded video
            provider option (a recent change by them has broken our connection
            to their service) was removed. Any Meeting, Zoom, Vsee, Doxy.me,
            Google Meet, are all supported in version 1.0.
          </Answer>

          <Question>12/02/2020 - v.89</Question>
          <Answer>
            Therapists and/or clients with unstable WIFI connections were losing
            connection with ActiveEMDR servers and not automatically being
            reconnected. Issued a temporary patch to force the ActiveEMDR server
            to reconnect when a loss was discovered.
          </Answer>

          <Question>11/10/2020 - v.868</Question>
          <Answer>Slight cosmetic and copy clarity changes.</Answer>

          <Question>11/09/2020 - v.865</Question>
          <Answer>
            Therapist app menu now displays properly without being hidden by app
            windows. Fixed multiple minor cosmetic issues.
          </Answer>

          <Question>11/04/2020 - v.86</Question>
          <Answer>
            Besides an updated look and more content for the website, we have
            made a few changes to the BLS tools. Most notably, you can once
            again, make size and speed changes prior to your client arriving and
            expect it to be sent to your client when they arrive. This release
            has also made some invisible preparations for the next major version
            of activeEMDR.
          </Answer>

          <Question>7/09/2020 - v.79c</Question>
          <Answer>
            Fixed bug where BLS playback would stop at 20 even with a higher
            repetition set.
          </Answer>

          <Question>7/09/2020 - v.79b</Question>
          <Answer>
            Added AnyMeeting.com video conferencing option. All of their plans
            include HIPAA compliance and are free until 2021. Starting in
            January, the Starter Plan (also with HIPAA) will remain free.
          </Answer>

          <Question>7/09/2020 - v.79a</Question>
          <Answer>
            Added solution to FAQ regarding Zoom Pro/Business users that may be
            experiencing issues connecting to Active EMDR.
          </Answer>

          <Question>7/09/2020 - v.79</Question>
          <Answer>
            More optimizations to the BLS playback system that should result in
            better performance for most users. We have postponed our major BLS
            playback upgrade.
          </Answer>

          <Answer addBottomMargin></Answer>
        </div>
      </div>
    </Wrapper>
  )
}

export default ChangeLog

import React, { useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { useCookies } from "react-cookie"
import { NewCookieExpiryDate } from "_data/global_functions.js"
import styled from "styled-components"
import {
  Wrapper,
  Answer,
  ButtonType1,
  Title,
  Highlight,
  ParagraphGroup,
} from "../styles"

const SelectProvider = () => {
  const g = useContext(TherapistContext)
  const [cookies, setCookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
  ])

  function CommonButton(label, provider, noVideo) {
    return (
      <ButtonType1
        transition={{
          type: "spring",
          stiffness: 330,
          damping: 60,
          mass: 6,
          restDelta: 0.01,
          restSpeed: 0.01,
          delay: 0,
        }}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ scale: 1.05, backgroundColor: "#984FB8" }}
        whileTap={{ scale: 0.99 }}
        onClick={() => {
          if (noVideo) {
            console.log(cookies._conn_clientUrl)
            setCookie("_conn_clientUrl", "noVideo", {
              path: "/",
              expires: NewCookieExpiryDate(360),
            })
            setCookie("_conn_therapistUrl", "noVideo", {
              path: "/",
              expires: NewCookieExpiryDate(360),
            })

            setCookie("provider", "noVideo", {
              path: "/",
              expires: NewCookieExpiryDate(360),
            })

            setCookie("integratedProvider", "false", {
              path: "/",
              expires: NewCookieExpiryDate(360),
            })

            g.set_providerChosen(false)
            g.set_vidWinOpen(false)
          }
          g.set_showPage(provider)
        }}
        style={{ perspective: "500px", backgroundColor: "#3196e6" }}
      >
        {label}
      </ButtonType1>
    )
  }

  return (
    <Wrapper>
      <div>
        <div>
          <Title multiplier=".9">
            Select the video conferencing provider you would like to use.
          </Title>
        </div>
        <ParagraphGroup>
          <Answer>
            The following video conferencing providers are either HIPAA
            compliant or offer a HIPAA compliant option. Unless you need to
            change your preferences, you only need to make this selection once
            as your preferences will be saved for future visits.
          </Answer>
        </ParagraphGroup>

        <div
          style={{ fontSize: "13px", marginLeft: "10px", marginTop: "20px" }}
        >
          <Highlight>Preferred video provider:</Highlight>
          <Answer>
            Doxy.me is fully embedded within the app interface and will provide
            the best user experience for you and your clients.
          </Answer>

          <ButtonWrapper>{CommonButton("Doxy.me", "Doxy")}</ButtonWrapper>
        </div>
        <div
          style={{ fontSize: "13px", marginLeft: "10px", marginTop: "20px" }}
        >
          <Highlight>Supported video providers:</Highlight>
          <Answer>
            The following providers cannot be embedded in the app, however, we
            will manage the connection for you and your clients. If you do not
            see your favorite provider listed, please use a{" "}
            <Highlight>Generic Connector</Highlight>
          </Answer>
          <ButtonRow>
            <ButtonWrapper>{CommonButton("Zoom", "Zoom")}</ButtonWrapper>
            <ButtonWrapper>
              {CommonButton("Google Meet", "Google")}
            </ButtonWrapper>
            <ButtonWrapper>
              {CommonButton("Any Meeting", "AnyMeeting")}
            </ButtonWrapper>
            <ButtonWrapper>{CommonButton("VSee Clinic", "VSee")}</ButtonWrapper>
            <ButtonWrapper>
              {CommonButton("Simple Practice", "SimplePractice")}
            </ButtonWrapper>
            <ButtonWrapper>
              {CommonButton("Generic Connector", "Generic")}
            </ButtonWrapper>
            {/* {CommonButton("No Video", "ProviderConfirmed", true)} */}
          </ButtonRow>
        </div>

        <div
          style={{ fontSize: "13px", marginLeft: "10px", marginTop: "20px" }}
        >
          <Highlight>No video:</Highlight>
          <Answer>
            If you do not plan on using video or want to handle conferencing
            manually and without our help, please select this option.
          </Answer>
          <ButtonWrapper>
            {CommonButton("No Video", "ProviderConfirmed", true)}
          </ButtonWrapper>
        </div>
      </div>
    </Wrapper>
  )
}

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`

const ButtonWrapper = styled.div``

export default SelectProvider

import React from "react"
import { socket } from "_app/common/SocketServer"
import {
  Wrapper,
  Title,
  Highlight,
  Answer,
  Comment,
  ParagraphGroup,
  ButtonType1,
} from "./styles"

const EndSession = (props) => {
  return (
    <Wrapper>
      <div style={{ maxWidth: "650px" }}>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">
            Confirm that you want to end the session with your client.
          </Title>
          <ParagraphGroup>
            <Answer>
              To end the <Highlight>ActiveEMDR</Highlight> session with your
              client, please click the button below. We will notify your client
              that the session has ended and close all connections to them.
            </Answer>
            <Comment>
              Please know that if you are using an embedded video conferencing
              provider, you will be disconnected from your client. If you are using
              a second browser for conferencing, we will not close that
              connection.
            </Comment>
            <ButtonType1
              transition={{
                type: "spring",
                stiffness: 330,
                damping: 60,
                mass: 6,
                restDelta: 0.01,
                restSpeed: 0.01,
                delay: 0,
              }}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.05, backgroundColor: "#984FB8" }}
              whileTap={{ scale: 0.99 }}
              onClick={() => {
                socket.emit("endSession", {
                  sessionName: props.sessionName,
                  from: "therapist",
                  to: "server",
                })
              }}
              style={{ perspective: "500px", backgroundColor: "#3196e6" }}
            >
              End Session
            </ButtonType1>
          </ParagraphGroup>
        </header>
      </div>
    </Wrapper>
  )
}

export default EndSession

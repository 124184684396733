import React from "react"
import {
  Wrapper,
  Title,
  Highlight,
  Question,
  Answer,
  // List,
  // ListItem,
  // Comment,
  ParagraphGroup,
} from "./styles"

const Welcome = () => {
  return (
    <Wrapper>
      <div style={{ maxWidth: "650px" }}>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Thank you for using ActiveEMDR!</Title>
          <Answer style={{ fontSize: "10px" }}></Answer>
          <ParagraphGroup>
            <Answer>
              We are happy that you are using ActiveEMDR and hope that it is meeting
              your needs. If you ever have an issue, please take a few moments
              to read over the <Highlight>Quick Start Guide</Highlight> and the{" "}
              <Highlight>Application FAQ</Highlight>. You may also find the{" "}
              <Highlight>Tutorial Videos</Highlight> helpful.
            </Answer>

            {/* <Question>Getting started...</Question>
            <Answer>
              If you are new to ActiveEMDR, please take a few moments to read
              over the <Highlight>Quick Start Guide</Highlight> and the{" "}
              <Highlight>Application FAQ</Highlight>. You may also find the{" "}
              <Highlight>Tutorial Videos</Highlight> helpful.
            </Answer> */}

            {/* <Question>Tell your colleagues!</Question>
            <Answer>
              Want to make sure that this software keeps getting updated with
              new features? Tell your colleagues. The time I pour into this app
              is directly proportional to the number of people I see using it
              and knowing that it is helping therapists and clients alike.
            </Answer>
            <Answer>
              If your favorite discussion group is talking about online/virtual
              EMDR tools, be sure to talk about ActiveEMDR. I'm not a therapist
              myself, so I'm not allowed in most of these groups, so I need your
              voice!
            </Answer> */}

            <Question>Have an issue or need support?</Question>
            <Answer>
              Please first try the FAQ from the menu on the left. Your question
              may of already been answered there. If you are still having
              trouble, feel free to use the Support link on the left.
            </Answer>
            {/* <Comment>
              If you have already been using v1.0 and Doxy together, please
              reset your video conference connection by choosing{" "}
              <Highlight>Select Video Provider</Highlight> and entering your
              room name again.
            </Comment> */}
            {/* <Answer>
              If you prefer another conference provider, no problem. We will
              still help negotiate the connection and manage the links for both
              you and your client. We want to make it as easy as possible for
              your client.
            </Answer> */}

            <Question>Direct Session Links:</Question>
            <Answer addBottomMargin>
              Don't forget, if you want to make it easy for your client, you
              should send them a Direct Sesssion Link (DSL). This is a
              personalized link to this session. With a DSL, your client does
              not need to enter a session password or navigate the ActiveEMDR
              website at all.
            </Answer>
          </ParagraphGroup>
        </header>

        <div>
          {/* <Question>Version 1.0 highlights:</Question>
          <List>
            <ListItem>An all new therapist dashboard.</ListItem>
            <ListItem>
              We have connectors for Zoom, Doxy (free, professional, and
              clinic), Any Meeting, Vsee, Google Meet, and Simple Practice. We
              even offer a "generic connector" that will work with most other
              web-based video conferencing providers. We will be adding more
              dedicated connectors over time.
            </ListItem>
            <ListItem>
              Your client will automatically receive the video conferencing
              configuration upon entering the session. Less work for them!
            </ListItem>
            <ListItem>
              Your video conference preferences and settings will be saved
              between sessions and will automatically load on your next visit.
            </ListItem>
            <ListItem>
              More reliable video conferencing integration with more provider
              choices.
            </ListItem>
          </List> */}
        </div>
      </div>
    </Wrapper>
  )
}

export default Welcome

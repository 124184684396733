import React, { useEffect, useState, useRef, useMemo } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FaLink } from "react-icons/fa"
import { Icon } from "_components/global/icon"
import { motion, useSpring } from "framer-motion"
// import { useCookies } from "react-cookie"
import { Cookies } from "react-cookie"
import { useOutsideClick } from "react-handle-outside-click"
import { ReturnSessionLink } from "./ReturnSessionLink"

export function CreateSessionLink(
  props,
  Button,
  ModalWrapper,
  Modal,
  CopyWrapper,
  Copy,
  HeaderToolTip,
  HeaderIcon
) {
  // const [cookie, set_cookies] = useCookies(["sessionName"])
  const cookie = useMemo(() => {
    return new Cookies()
  }, [])
  const [showModal, set_showModal] = useState(false)
  const [showToolTip, set_showToolTip] = useState(false)
  const sessionUrl = useRef()
  const toolTip_opacity = useSpring()
  const ref = useRef()

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  useEffect(() => {
    sessionUrl.current = ReturnSessionLink(cookie.get("sessionName"))
  }, [cookie])

  function Copied() {
    set_showModal(false)
  }

  function OnClick() {}

  function handleOutsideClick() {
    set_showModal(false)
  }

  useOutsideClick(ref, handleOutsideClick)

  return (
    <>
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.9 }}
      >
        <HeaderIcon
          onClick={() => {
            set_showModal(!showModal)
          }}
          onHoverStart={() => {
            set_showToolTip(true)
            toolTip_opacity.set(1)
          }}
          onHoverEnd={() => {
            set_showToolTip(false)
            toolTip_opacity.set(0)
          }}
        >
          {Icon(FaLink, OnClick, "15px")}
          <HeaderToolTip
            style={{
              opacity: toolTip_opacity,
              display: !showToolTip ? "none" : "",
            }}
          >
            Create a Direct Session Link
          </HeaderToolTip>
        </HeaderIcon>
      </motion.div>

      {showModal && (
        <ModalWrapper
          style={{
            top: props.smallScreen
              ? props.headerHeight_small
              : props.headerHeight_large,
          }}
          ref={ref}
        >
          <Modal style={{ backgroundColor: props.primaryAppColor }}>
            <CopyWrapper>
              <Copy>
                To invite a client directly to this session, share this link.
              </Copy>
            </CopyWrapper>
            <div style={{ margin: "0 0 10px 0", perspective: "500px" }}>
              <CopyToClipboard text={sessionUrl.current} onCopy={Copied}>
                <Button
                  style={{
                    perspective: "500px",
                    backgroundColor: "#f16c42",
                  }}
                  initial={{ rotate: 0.01, scale: 1.01 }}
                  transition={spring}
                  whileHover={{ scale: 1.025, rotate: 0.02 }}
                  whileTap={{ scale: 0.99 }}
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </div>
          </Modal>
        </ModalWrapper>
      )}
    </>
  )
}

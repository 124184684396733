import React, { useState, useContext } from "react"
import Header from "_app/therapist/header/Header"
import ShowPage from "_app/therapist/ShowPage"
import Nav from "_app/therapist/leftMenu/LeftMenu"
import styled from "styled-components"
import ResizeObserver from "react-resize-observer"
import IntegratedVideo from "_app/common/IntegratedVideo"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { Cookies } from "react-cookie"

const DashboardLayout = () => {
  const g = useContext(TherapistContext)
  const [smallScreen, set_smallScreen] = useState(false)
  const [viewPortHeight, set_viewPortHeight] = useState(1)
  const cookie = new Cookies()

  return (
    <Wrapper>
      <ResizeObserver
        onReflow={(rect) => {
          set_viewPortHeight(rect.height)
          if (rect.width < 750) set_smallScreen(true)
          else set_smallScreen(false)
        }}
      />

      <Screen style={{ height: viewPortHeight }}>
        <Left
          style={{
            maxWidth: smallScreen ? "50px" : "250px",
            minWidth: smallScreen ? "50px" : "250px",
          }}
        >
          <Nav smallScreen={smallScreen} set_smallScreen={set_smallScreen} />
        </Left>
        <Right>
          <HeaderWrapper>
            <Header smallScreen={smallScreen} />
          </HeaderWrapper>
          <ContentWrapper>
            <ShowPage />
          </ContentWrapper>
        </Right>
      </Screen>

      {cookie.get("integratedProvider") === "true" && g.vidWinOpen && (
        <IntegratedVideo isTherapist={true} url={g.therapistUrl} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
`

const Screen = styled.div`
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  display: flex;
`

const Left = styled.div`
  position: relative;
  background-color: white;
  z-index: 100;
  max-width: 200px;
  min-width: 200px;
  flex: 1 1 auto;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
`

const Right = styled.div`
  flex: 5 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
`

const HeaderWrapper = styled.div`
  background-color: white;
  height: 60px;
  position: relative;
  z-index: 1000;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
`

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  flex: auto;
  z-index: 5;
  overflow-y: auto;
`

export default DashboardLayout

import React from "react"
import { isMobile } from "react-device-detect"
import { motion } from "framer-motion"

export function Icon(IconName, onClick, iconSize, inputDelay) {
  const iconColor = "#3296e5"
  const hoverColor = "#174F99"
  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  let delayAnim = 0.6
  if (inputDelay != null) delayAnim = inputDelay

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: delayAnim }}
    >
      <motion.div
        isMobile={isMobile}
        transition={spring}
        animate={{ scale: 1 }}
        whileHover={{ scale: 1.2, color: hoverColor }}
        whileTap={{ scale: 1.1 }}
        onClick={onClick}
        style={{
          perspective: "500px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: iconColor,
        }}
      >
        <IconName size={iconSize} />
      </motion.div>
    </motion.div>
  )
}

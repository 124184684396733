import React, { useState, useEffect, useContext } from "react"
import { NewCookieExpiryDate } from "_data/global_functions.js"
import { motion } from "framer-motion"
import { useForm } from "react-hook-form"
import { useMitt } from "react-mitt"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import { SocketContext } from "_app/common/SocketServer"

const Reps = () => {
  const [cookies, set_cookies] = useCookies(["sessionName", "repLimit"])
  const { emitter } = useMitt()
  const socket = useContext(SocketContext)
  const [currRep, set_currRep] = useState(0)
  // had errors in list of values (register, handleSubmit, errors)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      newReps: cookies.repLimit,
    },
  })
  const onSubmit = (data) => {
    set_cookies("repLimit", data.newReps, {
      path: "/",
      expires: NewCookieExpiryDate(360),
    })
    socket.emit("repLimit", {
      sessionName: cookies.sessionName,
      limit: data.newReps,
    })
  }

  const spring = {
    type: "spring",
    damping: 17,
    stiffness: 100,
    mass: 1,
  }

  useEffect(() => {
    let mounted = true

    emitter.on("currRep", (data) => {
      if (mounted) set_currRep(data.count)
    })

    return function cleanup() {
      mounted = false
    }
  }, [emitter])

  return (
    <Content
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}
    >
      <Counter>
        <span>{currRep}</span> repetitions of
      </Counter>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="number"
          size="5"
          maxlength="5"
          name="newReps"
          ref={register}
        />
        <Button
          transition={spring}
          whileHover={{ scale: 1.025, backgroundColor: "#984FB8" }}
          whileTap={{
            scale: 0.95,
            buttonStatus: 1,
            backgroundColor: "#2384d2",
          }}
          type="submit"
          size="4"
          maxlength="4"
          style={{
            perspective: "500px",
          }}
        >
          Set
        </Button>
      </Form>
    </Content>
  )
}

const Content = styled(motion.div)`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Counter = styled.div`
  font-family: montserrat;
  font-size: 0.6em;
  font-weight: bold;
  margin-right: 12px;
  span {
    display: inline-block;
    width: 35px;
    margin-right: 7px;
    text-align: right;
    margin-top: 2px;
  }
`

const Form = styled.form`
  position: relative;
  z-index: 10;
  margin: 0;
  display: flex;
`

const Button = styled(motion.button)`
  font-family: montserrat;
  position: relative;
  font-size: 0.6em;
  margin-left: 5px;
  height: 30px;
  width: 60px;
  cursor: pointer;
  font-weight: 600;
  color: white;
  background-color: #245eab;
  border-style: none;
  border: none;
  outline: none;
  border-radius: 3px;
  text-align: center;
`

const Input = styled.input`
  font-family: montserrat;
  font-weight: 500;
  font-size: 0.6em;
  width: 40px;
  height: 30px;
  background-color: #2384d2;
  border: 0;
  border-radius: 3px;
  text-align: center;
  color: white;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default Reps

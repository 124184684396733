import React from "react"
import { Wrapper, Title, Question, Answer } from "./styles"

const AppFaq = () => {
  return (
    <Wrapper>
      <div>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">ActiveEMDR Application FAQ</Title>

          <Answer>
            The following frequently asked questions are related to the EMDR
            application. For more general website or ActiveEMDR questions and
            answers, please see the FAQ from the main website.
          </Answer>
        </header>

        <div style={{ marginLeft: "10px" }}>
          <Question>When was the application FAQ last updated?</Question>
          <Answer>
            This FAQ was last updated on May 11th, 2021. The latest Q&A is
            first.
          </Answer>

          <Question>
            My client is not hearing the BLS audio tones. What can I do?
          </Question>
          <Answer>
            As of this writing, ActiveEMDR does not support audio on mobile
            devices. Please make sure your client is not using a phone or
            tablet.
          </Answer>
          <Answer>
            Please make sure that the audio is not muted. There are two audio
            mute buttons located in the BLS controls area along with the
            play/stop, size, and speed buttons. If your client is still not
            hearing anything, have them click the "squiggly" line icon in the
            upper right of their screen. This will emit an audio tone. The BLS
            audio should work after clicking the button.
          </Answer>
          <Answer>
            What is going on? Some browsers do not allow websites to play audio
            unless the user (your client) interacts with the website. Pressing
            this button counts as interaction and the browser will now let the
            BLS audio through.
          </Answer>

          <Question>
            Why is Doxy.me the only provider embedded within ActiveEMDR?
          </Question>
          <Answer>
            Doxy.me is currently the only HIPAA compliant provider that allows
            other websites to freely integrate their tools. Most providers do
            not allow this at all, and those that do (Zoom for example), make it
            possible, but only at a cost of several thousand dollars per month.
            For this reason, we are Doxy.me fans and encourage everyone to use
            them because of their policies. This is far and away the easiest to
            use and most convenient for you and your clients.
          </Answer>

          {/* <Question>I cannot connect to the session server!</Question>
          <Answer>
            Make sure you and your client have Javascript enabled in your web
            browser. This is on by default, but some extensions may turn it off.
            You also need to make sure your browser will accept cookies. In some
            rare circumstances our session server has been blocked by either
            your local firewall or your internet service provider.  
          </Answer> */}

          <Question>What is a Direct Session Link?</Question>
          <Answer>
            A Direct Session Link (DSL) is a link for your client that will
            connect them directly to an ActiveEMDR session. They will not have
            to enter a Session Password or even navigate the ActiveEMDR website.
            To create a DSL, look at the top right of your screen and click on
            the link icon. Clicking on the "Copy Link" button will copy the link
            to your buffer. You can now paste the link in an email to your
            client.
          </Answer>

          <Question>Why isn't the BLS playback smooth?</Question>
          <Answer>
            There can be a couple of different reasons why the ball may not be
            moving smoothly. If you are using ActiveEMDR on an older computer or
            a mobile device, there may not be enough processing power for smooth
            playback. This could be caused by the computer/device processor or
            the graphics card.
          </Answer>
          <Answer>
            Your monitor could be another reason for stuttering playback. Every
            monitor has a refresh rate that is measured in Hz(hertz). Put
            simply, the Hz is how many times per second the monitor will redraw
            your screen. The higher the Hz, the smoother the playback will be.
            If it becomes more of a problem the faster the ball is moving, this
            is probably the issue you are having. A fast moving object may
            appear to stutter on monitors with a refresh rate below 60Hz. There
            is nothing you can do about this outside of purchasing a new
            monitor. Typically, a monitor with a refresh rate higher than 60Hz
            will be decent. Anything above 120Hz will be butter smooth.
          </Answer>

          <Question>Why is the BLS playback pausing?</Question>
          <Answer>
            By default, some web browsers will stop updating when they are no
            longer visible or covered by another application. What this means is
            that if you minimize the BLS browser window, or expand another
            window to fullscreen (therefore covering the BLS window), the BLS
            may pause, only to continue where it left off once the BLS is
            visible again. You and your client's BLS are now out of sync with
            each other.
          </Answer>
          <Answer>
            As an exmaple, if you are 10 reps into a 20 rep set and either
            minimize the BLS browser or fully cover it with another application,
            the playback will pause on your screen, but not your client's
            screen. You and your client are now out of sync. Your client's BLS
            will stop after rep 20, but it will take you a bit longer to finish.
            You can hit your stop button to stop the playback on your end. The
            next time you press the Play button, the two of you will be in sync
            again.
          </Answer>
          <Answer>
            The solution is to never minimize the BLS playback or expand another
            application or browser window that completely covers the BLS window.
            As long as a tiny sliver of the BLS window is showing, it will not
            pause.
          </Answer>
          <Answer>
            There is nothing we can do to keep this from happening. This is
            something that browsers implement to save resources. It can
            especially be a problem on mobile devices where you cannot have the
            two browsers open at the same time. If you are using a mobile
            device, you can switch to the BLS browser and press Play, then
            switch to the video conferencing browser to watch your client during
            processing. When the BLS has stopped on your client's screen, you
            can flip back to the BLS browser and simply stop the BLS playback on
            your screen with the Stop button.
          </Answer>

          <Question>
            When I confirm a BLS speed change, why does playback stop?
          </Question>
          <Answer>
            If you are adjusting the speed while the BLS is playing, it will
            stop once you confirm (by clicking on the checkmark) the change.
            This is by design. While the speed updates immediately as you move
            the slider, your client is updated once you confirm the new speed.
            If we did not stop the playback, you and your client would be out of
            sync with one another.
          </Answer>

          {/* <Question>
            Why aren't the BLS and video conferencing previews "embedded" into
            the application anymore?
          </Question>
          <Answer>
            This change was made so that we could offer more video conferencing
            choices, and more importantly, not be at risk of it breaking
            everytime the video conferencing provider makes a change on their
            end. There are a few drawbacks to this new method, but the positives
            outweigh the negatives and decided this is the best route to take.
          </Answer> */}

          <Question>Why are you opening a new browser window?!</Question>
          <Answer>
            In order to be compatible with almost every web-based video
            conferencing provider, all but our preferred video providers are now
            opened in a second browser. This means that the app (that you are
            looking at when reading this) takes one browser and the video
            conferencing takes another.{" "}
          </Answer>
          <Answer>
            We suggest arranging the two browsers so that you can see both at
            the same time. Most therapists make their video conferencing window
            large and the BLS window small. Whereas your clients should probably
            make the BLS window large and the video conferencing small. We
            encourage you to ask your client to stretch the BLS window to the
            maximum width of their monitor.
          </Answer>

          <Question>Why isn't the video conferencing browser opening?</Question>
          <Answer>
            This is probably due to a pop-up blocker. Either whitelist
            activeemdr.com or turn off pop-up blocking completely. If it is
            still not working, you can manually open a new browser and
            copy/paste the provided link into that new browser.
          </Answer>

          {/* <Question>Can I close either of the browser windows?</Question>
          <Answer>
            Closing the ActiveEMDR App browser window will disconnect you from
            our server and you will lose the ability to use BLS with your
            client. Closing the video conferencing window will drop the video
            connection between you and your client. If you accidentally close
            the video window, simply click "Show Conferencing Conferencing" on
            the left menu to re-open it.
          </Answer>
          <Answer>
            If you have finished EMDR processing with your client, you can close
            the ActiveEMDR App and your video connection will remain intact.
          </Answer> */}

          <Question>
            Your tools opened a new browser for video conferencing, but it has
            disappeared!
          </Question>
          <Answer>
            Chances are, it is hidden behind another window. If you are on a
            Windows computer, you can try to ALT-TAB to find it, or look on your
            task bar. As a last ditch effort, you can "Close" the video
            conference window from the left menu and re-open a new one from that
            same menu.
          </Answer>

          {/* <Question>
            Help! I'm on a mobile device and cannot see the video conference
            window.
          </Question>
          <Answer>
            Because we are opening a new browser to the video conference
            provider, mobile users will have to switch between browsers to
            start/stop BLS and then see their client. The reason is that most
            mobile devices will only show one web browser at a time. We strong
            encourage therapists and their clients use a laptop or desktop
            computer.
          </Answer> */}
          {/* <Answer>
            A decision had to be made whether we should continue to support
            video and BLS in the same app (like the older version of ActiveEMDR)
            or open separate browsers to be able to support more providers and
            have more stability. Because there are far more therapists using a
            desktop computer for ActiveEMDR, and because of the benefits of
            opening a new browser, we went in that direction.
          </Answer> */}
          {/* <Answer>
            Please know that you can still use mobile devices, it just takes a
            little bit of extra effort.
          </Answer> */}

          <Question>
            Can I use ActiveEMDR for all of my teletherapy sessions?
          </Question>
          <Answer>
            Yes! A handful of therapists are already doing this as it allow them
            to jump into EMDR procesing when it was not originally planned. It
            also will provide a standardized way to connect to your client that
            looks the same for EMDR and non-EMDR teletherapy sessions.
          </Answer>

          <Question>How secure are Session Passwords?</Question>
          <Answer>
            Session Passwords are not currently encrypted. If someone other than
            your client knows or guesses a Session Password, they will not
            receive any client information, data, or PHI. The worst case
            scenario is that a third party can intercept the link to your video
            conferencing provider. For this reason, it is a good idea to either
            password protect your video sessions (done through your conference
            provider, NOT ActiveEMDR) or control who enters your room (also done
            through your conference provider).
          </Answer>
          {/* <Answer>
            On the other hand video conferencing security and encryption is
            important. It is also a HIPAA violation to use a conference provider
            that is not HIPAA compliant. That is the responsibility of whatever
            video conferencing provider you are using. Please refer to them for
            further questions.
          </Answer> */}

          <Question>Is ActiveEMDR HIPAA compliant?</Question>
          <Answer>
            As a conduit, we are not required to be HIPAA compliant. We do not
            transmit, store, or even see Protected Health Information. We have
            no access to client information and we cannot see what is happening
            on your video call. The only information that we have access to is
            BLS speed, size, and repetitions. We also transmit video conference
            links to your client. However, we do not monitor, save, or otherwise
            evaluate any of this information.
          </Answer>

          <Question>What if I have another question?</Question>
          <Answer addBottomMargin>
            Please contact us using the "Support" menu item along the left side
            of your screen. We will get back to you ASAP.
          </Answer>
        </div>
      </div>
    </Wrapper>
  )
}

export default AppFaq

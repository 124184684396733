import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { motion, AnimatePresence } from "framer-motion"
import PlayStop from "./PlayStop"
import Reps from "./Reps"
import BallSize from "./BallSize"
import BallSpeed from "./BallSpeed"
import styled from "styled-components"
import VolumeTherapist from "./VolumeTherapist"
import VolumePatient from "./VolumePatient"

const iconSize = "28px"
const iconColor = "#3296e5"
const spring = {
  type: "spring",
  damping: 17,
  stiffness: 200,
}

const Controls = () => {
  const [showSingleControl, set_showSingleControl] = useState(false)
  const [showSpeedSlider, set_showSpeedSlider] = useState(false)
  const [showSizeSlider, set_showSizeSlider] = useState(false)

  function CB_showSlider(type) {
    // This callback is fired when either the speed or size icon is clicked.
    // It turns on SingleControl state and either speed or size state.
    set_showSingleControl(true)
    if (type === "speed") set_showSpeedSlider(true)
    if (type === "size") set_showSizeSlider(true)
  }

  return (
    <Wrapper>
      <AnimatePresence>
        {showSingleControl && (
          <SingleControlWrapper>
            <Fill
              key="kdk3"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.25, delay: 0.1 }}
              exit={{ scaleX: 0 }}
            >
              {showSpeedSlider && (
                <BallSpeed
                  showSpeedSlider={showSpeedSlider}
                  set_showSpeedSlider={set_showSpeedSlider}
                  set_showSingleControl={set_showSingleControl}
                />
              )}
              {showSizeSlider && (
                <BallSize
                  showSizeSlider={showSizeSlider}
                  set_showSizeSlider={set_showSizeSlider}
                  set_showSingleControl={set_showSingleControl}
                />
              )}
            </Fill>
          </SingleControlWrapper>
        )}
      </AnimatePresence>

      <ControlsWrapper>
        <ControlsArea isMobile={isMobile}>
          <IconContainer>
            <div />
            <Icon>
              <PlayStop
                iconSize={iconSize}
                iconColor={iconColor}
                spring={spring}
              />
            </Icon>
            <Icon>
              <BallSpeed
                iconSize={iconSize}
                iconColor={iconColor}
                CB_showSlider={CB_showSlider}
                spring={spring}
              />
            </Icon>
            <Icon>
              <BallSize
                iconSize={iconSize}
                iconColor={iconColor}
                CB_showSlider={CB_showSlider}
                spring={spring}
              />
            </Icon>
            <Icon>
              <VolumeTherapist
                iconSize={iconSize}
                iconColor={iconColor}
                spring={spring}
              />
            </Icon>
            <Icon>
              <VolumePatient
                iconSize={iconSize}
                iconColor={iconColor}
                spring={spring}
              />
            </Icon>
            <div />
          </IconContainer>
        </ControlsArea>
        <RepsWrapper>
          <Reps />
        </RepsWrapper>
      </ControlsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const SingleControlWrapper = styled(motion.div)`
  position: absolute;
  top: 0px;
  z-index: 5;
  width: 100%;
  height: 100%;
`

const ControlsWrapper = styled(motion.div)`
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.isMobile ? "#909096;" : "#f2f7fb")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* border-radius: ${(props) =>
    props.isMobile ? "0 0 0 0" : "0 0 10px 10px"}; */
`

const ControlsArea = styled.div`
  width: 100%;
  width: ${(props) => (props.isMobile ? "80%" : "300px")};
  max-width: 500px;
  color: #f3f3f3;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RepsWrapper = styled.div`
  background-color: #c1def56e;
  padding: 5px 15px 5px 0px;
  margin-top: 15px;
  border-radius: 5px;
`

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: ". . . . . . .";
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Fill = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: montserrat;
  font-weight: bold;
  text-align: center;
  background-color: #225fac;
  background-color: #d7d8de;
  background-color: #d1dce6;
`

export default Controls

import React, { useContext } from "react"
import Bls from "_app/common/bls/Bls"
import Controls from "_app/therapist/emdr/controls/Controls"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"

const Emdr = () => {
  const g = useContext(TherapistContext)
  return (
    <Wrapper>
      <BlsWrapper>
        {/* <VidWin>
          <VidWin2>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/1-nXrqOHXr4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VidWin2>
        </VidWin> */}
        <Bls isTherapist={true} />
      </BlsWrapper>
      <Session isMobile={isMobile}>({g.sessionName})</Session>
      <ControlWrapper>
        <Controls />
      </ControlWrapper>
    </Wrapper>
  )
}

const VidWin = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const VidWin2 = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: orange;
`

const Wrapper = styled.div`
  /* this was the container that is causing issues on Safari browsers.
  It was fixed by putting height 100% on parent element in Dashboard*/
  /* position: relative; */
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
`

const BlsWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1 1 0;
`

const Session = styled.div`
  width: 98%;
  text-align: right;
  font-weight: 600;
  font-family: montserrat;
  font-size: 15px;
  font-size: ${(props) => (props.isMobile ? "12px" : "14px")};
  color: black;
  opacity: 0.45;
`

const ControlWrapper = styled.div`
  height: 180px;
  width: 100%;
  box-shadow: 0 -0.125rem 0.625rem rgba(90, 97, 105, 0.22);
  @media only screen and (max-height: 500px) {
    height: 110px;
  }
`

export default Emdr

import React from "react"
import { Wrapper, Title, Highlight, Answer } from "./styles"

const ProviderConfirmed = (props) => {
  return (
    <Wrapper>
      <div style={{ maxWidth: "650px" }}>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Video conference preferences accepted.</Title>
          <div style={{ marginTop: "6px", paddingLeft: "5px" }}>
            <Answer>
              Your preferences will be remembered each time you visit our
              website. You can change providers at any time by clicking{" "}
              <Highlight>Select Video Provider</Highlight> on the left.
            </Answer>
            {props.clientUrl !== "noVideo" && (
              <Answer>
                To begin video conferencing with your client, click{" "}
                <Highlight>Show Conferencing</Highlight> from the left menu.
              </Answer>
            )}
            {props.clientUrl === "noVideo" && (
              <Answer addBottomMargin>
                To begin EMDR processing, select <Highlight>BLS</Highlight> from
                the left menu.
              </Answer>
            )}
          </div>
        </header>
      </div>
    </Wrapper>
  )
}

export default ProviderConfirmed

import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import TherapistApp from "_app/therapist/Therapist"
import Seo from "_components/Seo/therapist"
import { SocketContext, socket } from "_app/common/SocketServer"
import { UserProvider } from "_app/therapist/TherapistGlobalVars"

const Therapist = () => {
  return (
    <UserProvider>
      <SocketContext.Provider value={socket}>
        <Seo />
        <TherapistApp />
      </SocketContext.Provider>
    </UserProvider>
  )
}

export default Therapist

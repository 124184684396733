import React, { useState, useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { SetCookie } from "_app/common/utils/SetCookie"
import styled from "styled-components"
import {
  Wrapper,
  Title,
  Form,
  Answer,
  Highlight,
  Comment,
  ButtonType1,
} from "../styles"

const Generic = () => {
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
  ])
  const setCookie = SetCookie(set_cookie)
  const [isError, set_isError] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      therapistLink: cookie._conn_therapistUrl,
      clientLink: cookie._conn_clientUrl,
    },
  })

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  function SubmitForm(data) {
    try {
      console.log(isError)
      const clientUrl = GetValidUrl(data.clientLink)
      const therapistUrl = GetValidUrl(data.therapistLink)
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", clientUrl)
      setCookie("_conn_therapistUrl", therapistUrl)
      setCookie("provider", "generic")
      setCookie("integratedProvider", "false")

    } catch (err) {
      set_isError(true)
    }
  }

  // :s: Get valid url
  function GetValidUrl(url) {
    let newUrl = window.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, "")

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}`
    }

    return newUrl
  }

  return (
    <Wrapper>
      <div>
        <>
          <Title multiplier=".9">Generic Connector</Title>
          <Answer style={{ marginTop: "3px" }}>
            The <Highlight>Generic Connector</Highlight> can be used for most
            browser-based video conferencing providers. It will not work with
            providers that only support video conferencing using an installed
            app/program.
          </Answer>

          <Comment>
            To use this connector, visit the provider's website and find the
            video conference link that they ask you to share with your client.
            Enter that link in the first box below.
          </Comment>

          <Comment>
            You will also need the link that you, the therapist, would use to
            join the conference. This may or may not be the same link that the
            client will use.
          </Comment>
        </>
      </div>

      <Form onSubmit={handleSubmit(SubmitForm)}>
        <Row>
          <InputContainer>
            <Answer style={{ margin: 0 }}>* Client Link</Answer>
            <FormInput name="clientLink" type="text" ref={register} required />
          </InputContainer>
        </Row>
        <Row>
          <InputContainer>
            <Answer style={{ margin: 0 }}>* Therapist Link</Answer>
            <FormInput
              name="therapistLink"
              type="text"
              ref={register}
              required
            />
          </InputContainer>
        </Row>
        <Row style={{ width: "150px", marginLeft: "10px", padding: 0 }}>
          <ButtonType1
            style={{
              perspective: "500px",
              width: "150px",
              padding: 0,
            }}
            initial={{ rotate: 0.01, scale: 1.01 }}
            transition={spring}
            whileHover={{
              scale: 1.05,
              rotate: 0.02,
              backgroundColor: "#984FB8",
            }}
            whileTap={{ scale: 0.99 }}
          >
            <span>Submit</span>
          </ButtonType1>
        </Row>
      </Form>
    </Wrapper>
  )
}

const InputContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

const Row = styled.div`
  width: 400px;
  margin: 10px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormInput = styled.input`
  height: 40px;
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: #2196f3;
  width: 100%;
`

export default Generic

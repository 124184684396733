import React, { useEffect, useContext } from "react"
import { Logger, NewCookieExpiryDate } from "_data/global_functions.js"
import { ScaleRange } from "_data/global_functions"
import { SocketContext, socket } from "_app/common/SocketServer"
import { useCookies } from "react-cookie"
import { MittProvider } from "react-mitt"
import InitiateSession from "_app/therapist/InitiateSession"
import TherapistSocket from "_app/therapist/socket/TherapistSocket"
import Dashboard from "_app/therapist/Dashboard"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"

const TherapistApp = () => {
  const g = useContext(TherapistContext)
  const [cookies, set_cookies] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "repLimit",
    "speedVis",
    "speed",
    "sizeVis",
    "size",
    "integratedProvider",
  ])

  // useEffect(() => {
  //   if (g.servAvailable != null) {
  //     console.log("-----")
  //     console.log("g.servAvailable: " + g.servAvailable)
  //     console.log("g.servConnected: " + g.servConnected)
  //     console.log("g.providerChosen: " + g.providerChosen)
  //     console.log("g.vidWinOpen: " + g.vidWinOpen)
  //     console.log("g.reconnAfterDisc: " + g.reconnAfterDisc)
  //     console.log("g.client_vidWinOpen: " + g.client_vidWinOpen)
  //     console.log("g.therapistUrl: " + g.therapistUrl)
  //     console.log("g.clientUrl: " + g.clientUrl)
  //     console.log("g.clientConnected: " + g.clientConnected)
  //     console.log("g.showPage: " + g.showPage)
  //     console.log("-----")
  //   }
  // }, [
  //   g.servAvailable,
  //   g.servConnected,
  //   g.providerChosen,
  //   g.vidWinOpen,
  //   g.reconnAfterDisc,
  //   g.client_vidWinOpen,
  //   g.therapistUrl,
  //   g.clientUrl,
  //   g.clientConnected,
  //   g.showPage,
  // ])

  // :s: Check for Therapist/Patient URL cookies and set states appropriately.
  useEffect(() => {
    if (cookies._conn_therapistUrl != null) {
      Logger("_conn_therapistUrl has a value: " + cookies._conn_therapistUrl)
      g.set_therapistUrl(cookies._conn_therapistUrl)
      if (cookies._conn_therapistUrl === "noVideo") g.set_providerChosen(false)
      else g.set_providerChosen(true)
    } else {
      Logger("_conn_therapistUrl does not exist.")
      g.set_providerChosen(false)
      g.set_therapistUrl("noVideo")
      CookieSet("_conn_therapistUrl", "noVideo")
    }

    if (cookies._conn_clientUrl != null) {
      Logger("_conn_clientUrl has a value: " + cookies._conn_clientUrl)
      g.set_clientUrl(cookies._conn_clientUrl)
      if (cookies._conn_clientUrl === "noVideo") g.set_providerChosen(false)
      else g.set_providerChosen(true)
    } else {
      Logger("_conn_clientUrl does not exist.")
      g.set_providerChosen(false)
      g.set_clientUrl("noVideo")
      CookieSet("_conn_clientUrl", "noVideo")
    }

    function CookieSet(name, val) {
      set_cookies(name, val, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })
    }
  }, [
    g,
    set_cookies,
    cookies._conn_therapistUrl,
    cookies._conn_clientUrl,
    cookies.integratedProvider,
  ])

  // :s: Check to see if BLS cookies exist.  If not, create them.
  useEffect(() => {
    if (!cookies.integratedProvider) CookieSet("integratedProvider", "false")
    if (!cookies.repLimit || cookies.repLimit === "") CookieSet("repLimit", 20)

    if (!cookies.speedVis || cookies.speedVis === "") {
      CookieSet("speedVis", 128)
      const actualSpeed = ScaleRange(128, 15, 240, 2, 0.125).toPrecision(3)
      CookieSet("speed", actualSpeed)
    }

    if (!cookies.sizeVis || cookies.sizeVis === "") {
      CookieSet("sizeVis", 50)
      const actualSize = ScaleRange(50, 1, 100, 0.2, 4).toPrecision(3)
      CookieSet("size", actualSize)
    }

    function CookieSet(name, val) {
      set_cookies(name, val, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })
    }
  }, [
    cookies.integratedProvider,
    cookies.repLimit,
    cookies.speedVis,
    cookies.speed,
    cookies.sizeVis,
    cookies.size,
    set_cookies,
  ])

  // :s: Change show video menu text on state change.
  // --> Reworked w/ new states on 2/23/21
  // --> Commented out on 3/7.  Will need this functionality again.
  // --> Maybe watch toggleVideo emitter?
  useEffect(() => {
    if (g.therapistUrl === "noVideo") {
      g.set_leftMenuVidText("No Video Selected")
    } else {
      if (!g.vidWinOpen) {
        if (g.therapistUrl == null) g.set_leftMenuVidText("No Video Selected")
        else g.set_leftMenuVidText("Show Conferencing")
      }
    }
  }, [g])

  // -->
  return (
    <>
      <SocketContext.Provider value={socket}>
        <MittProvider>
          <TherapistSocket />
          {!g.servConnected && <InitiateSession />}
          {g.servConnected && <Dashboard />}
        </MittProvider>
      </SocketContext.Provider>
    </>
  )
}

export default TherapistApp

import React, { useState, useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { SetCookie } from "_app/common/utils/SetCookie"
import {
  Wrapper,
  Title,
  Form,
  FormRow,
  FormInput,
  Answer,
  Highlight,
  Comment,
  ButtonType1,
} from "../styles"

const Google = () => {
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "_conn_googleMeet",
  ])
  const setCookie = SetCookie(set_cookie)
  const [isError, set_isError] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      userInput: cookie._conn_googleMeet,
    },
  })

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  // :s: Process form data
  function SubmitForm(data) {
    const fromUser = GetValidUrl(data.userInput)

    try {
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", fromUser)
      setCookie("_conn_therapistUrl", fromUser)
      setCookie("_conn_googleMeet", fromUser)
      setCookie("provider", "googleMeet")
      setCookie("integratedProvider", "false")
    } catch (err) {
      set_isError(true)
    }
  }

  // :s: Get valid url
  function GetValidUrl(url) {
    let newUrl = window.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, "")

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}`
    }

    return newUrl
  }

  return (
    <Wrapper>
      <div>
        <>
          <Title multiplier=".9">Google Meet</Title>
          <Answer style={{ marginTop: "3px" }}>
            Please enter the entire Google Meet invite link below. As we will
            not verify this link, please check for accuracy before submitting.
          </Answer>
          <Comment>
            To avoid confusion, do not send the Google Meet invite link directly
            to your client. We handle the video conference connection for them
            once they arrive to the ActiveEMDR session.
          </Comment>
        </>
      </div>

      <Form onSubmit={handleSubmit(SubmitForm)}>
        <FormRow>
          <FormInput name="userInput" type="text" ref={register} required />
          <ButtonType1
            style={{
              perspective: "500px",
            }}
            initial={{ rotate: 0.01, scale: 1.01 }}
            transition={spring}
            whileHover={{
              scale: 1.05,
              rotate: 0.02,
              backgroundColor: "#984FB8",
            }}
            whileTap={{ scale: 0.99 }}
          >
            <span>Set Google Meet Link</span>
          </ButtonType1>
        </FormRow>
        {isError && (
          <Answer style={{ marginTop: "15px" }}>
            <Highlight>
              There seems to be a problem with the link that you entered. Copy
              and paste the full Google Meet invitation URL. If you continue to
              have issues, please contact support from the menu on the left.
            </Highlight>
          </Answer>
        )}
      </Form>
    </Wrapper>
  )
}

export default Google

import React, { useState } from "react"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isMobile } from "react-device-detect"
import { motion } from "framer-motion"
import RCSlider from "rc-slider"
import styled from "styled-components"
import "rc-slider/assets/index.css"

/*
 * The slider receives a value from startVal to endVal and outputs
 * value outVal.
 *
 * Based off of: https://codesandbox.io/s/3ctj7?file=/demo.js
 *
 */

const Slider = (props) => {
  const [sliderVal, setSliderVal] = useState(props.initialValue)

  const buttonSpring = {
    type: "spring",
  }

  const onSliderChange = (value) => {
    setSliderVal(value)
    props.callback(false, value)
  }

  function CancelChange_onClick() {
    props.callback(true, props.initialValue)
  }

  function ConfirmChange_onClick() {
    props.callback(true, sliderVal)
  }

  return (
    <Content>
      <Wrapper>
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.35 }}
        >
          <Icon
            transition={buttonSpring}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.7, buttonStatus: 1 }}
            onClick={CancelChange_onClick}
            style={{ marginRight: "10px" }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Icon>
        </motion.div>
        <SliderWrapper>
          <Control>
            <RCSlider
              min={props.min}
              max={props.max}
              startPoint={props.initialValue}
              defaultValue={props.initialValue}
              onChange={onSliderChange}
              handleStyle={[
                {
                  borderColor: "#607d8b",
                  borderWidth: "1px",
                  backgroundColor: "#3196e6",
                  height: "25px",
                  width: "25px",
                  marginTop: "-10px",
                },
              ]}
              railStyle={{
                background: "#080a33",
              }}
              trackStyle={[
                {
                  backgroundColor: isMobile ? "#d4d4d4" : "#325eb7",
                },
              ]}
            />
          </Control>
        </SliderWrapper>

        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.35 }}
        >
          <Icon
            transition={buttonSpring}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.7, buttonStatus: 1 }}
            onClick={ConfirmChange_onClick}
            style={{ marginLeft: "5px" }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Icon>
        </motion.div>
      </Wrapper>
      <Readout
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.35 }}
      >
        {props.label}: <span>{sliderVal}</span>
      </Readout>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
  width: 100%;
  /* height: 100%; */
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
  width: 90%;
  height: 100%;
  max-width: 450px;
  /* @media only screen and (max-width: 430px) {
    width: 320px;
  } */
`

const Icon = styled(motion.div)`
  color: #235eac;
  font-size: 25px;
  height: 29px;
  width: 29px;
  cursor: pointer;
  /* background-color: grey; */
`

const SliderWrapper = styled.div`
  flex: 15 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Control = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Readout = styled(motion.div)`
  position: relative;
  height: 15px;
  margin-left: 15px;
  margin-top: 5px;
  font-family: montserrat;
  color: #1b3556;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 30px;
    text-align: left;
    margin-left: 5px;
  }
`

export default Slider

import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import {
  color_subHeading,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"

export const color_waiting = "#97b3c1"
export const color_connected = "#3296e5"
export const color_disconnected = "#dc2b1e"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 7vh 5vw 1vh 5vw;
  @media only screen and (min-width: 950px) {
    padding: 10vh 5vw 1vh 5vw;
  }
  max-width: 750px;
`

export const Comment = styled.div`
  font-style: italic;
  margin-left: 20px;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.4em;
  margin-bottom: 2vh;
`

export const Link = styled.span`
  font-weight: 800;
  cursor: pointer;
  color: ${color_paragraphHighlite};
`

export const Highlight = styled.span`
  font-family: montserrat;
  font-weight: 800;
  color: ${color_paragraphHighlite};
`

export const Question = styled.h2`
  font-family: montserrat;
  margin: 6vh 0 0vh 0;
  line-height: 1.4em;
  font-size: 1.1em;
  font-weight: 800;
  color: ${color_subHeading};
`

export const ParagraphGroup = styled.div`
  margin-top: 4px;
  margin-left: 5px;
`

export const Answer = styled.h3`
  font-family: montserrat;
  margin: 0 0 1vh 0.5vw;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.6em;

  ${(props) =>
    props.addBottomMargin &&
    css`
      margin-bottom: 200px;
    `}
`

export const List = styled.ul`
  font-family: montserrat;
  font-size: 15px;
  margin-top: 15px;
  /* line-height: 20px; */
`

export const ListItem = styled.li`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.45em;
  margin: 7px;
`

export const Title = styled.div`
  font-family: montserrat;
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: 800;
  color: #3196e6;
  text-align: left;
`

export const SubTitle = styled.div`
  font-family: montserrat;
  font-weight: 500;
  font-size: 12px;
  color: beige;
  margin-top: 15px;
  line-height: 1.6em;
  text-align: left;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  max-width: 500px;
  margin: 0;
  padding: 0;
`

export const GoBack = styled.div`
  font-family: montserrat;
  font-size: 0.7em;
  font-weight: 800;
  text-decoration: none;
  cursor: pointer;
  margin: 0px 0 0 0;
  padding: 0;
  text-align: center;
`

export const Form = styled.form`
  font-family: montserrat;
  font-weight: bold;
  height: 100%;
  width: 100%;
  /* margin: 20px 0 8px 0; */
`

export const FormRow = styled.div`
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormInput = styled.input`
  height: 40px;
  border: none;
  flex: 2 1 0;
  outline: none;
  padding: 15px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: #2196f3;
  width: 100px;
`

export const ButtonType1 = styled(motion.button)`
  flex: 1 1 0;
  margin: 5px 10px 10px 5px;
  font-family: montserrat;
  cursor: pointer;
  font-weight: 600;
  font-size: .8em;
  color: white;
  padding: 10px 20px 10px 20px;
  background-color: #ae54d5;
  width: 150px;
  /* min-width: 110px; */
  border: none;
  outline: none;
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4), 1px 1px 3px rgba(0, 0, 0, 0.4);
  /* height: 40px; */
  height: 45px;
`

export const Button = styled(motion.button)`
  flex: 2 1 0;
  height: 100%;
  font-family: montserrat;
  margin-left: 15px;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  /* font-weight: bold; */
  color: white;
  border: 0;
  border-radius: 7px;
  text-align: center;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.4), 12px 12px 25px rgba(0, 0, 0, 0.24);
  border: none;
  outline: none;
`

// export const Modal = styled.div`
//   position: relative;
//   z-index: 100000;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   width: 100%;
//   border-radius: 0 0 0 10px;
//   box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
//     0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
//     0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
//     0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
// `

// export const CopyWrapper = styled.div`
//   flex: 1 1 0;
//   padding: 0 10px 0 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// export const Copy = styled.div`
//   font-family: montserrat;
//   font-size: 12px;
//   font-weight: 600;
//   line-height: 1.6em;
//   padding: 0;
//   margin: 0;
// `

// export const HeaderButton = styled(motion.button)`
//   height: 35px;
//   padding: 0 10px 0 10px;
//   font-family: montserrat;
//   margin-left: 15px;
//   font-size: 0.7em;
//   cursor: pointer;
//   font-weight: bold;
//   color: white;
//   border: 0;
//   border-radius: 7px;
//   text-align: center;
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4), 12px 12px 25px rgba(0, 0, 0, 0.15);
//   border: none;
//   outline: none;
// `

export const HeaderIcon = styled(motion.div)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 2px;
`

export const HeaderToolTip = styled(motion.div)`
  position: absolute;
  z-index: 10000;
  top: 45px;
  font-family: montserrat;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  background-color: #d1dce6;
  color: #245eab;
  padding: 5px 15px 5px 15px;
  margin-right: 10px;
  text-align: center;
  border-radius: 3px;
`

// export const ModalWrapper = styled.div`
//   position: absolute;
//   width: 300px;
//   height: 120px;
//   right: 0;
// `

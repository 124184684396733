import React, { useState, useEffect, useContext } from "react"
import { FaServer } from "react-icons/fa"
import { motion, useSpring } from "framer-motion"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"

// --> Reworked w/ new states on 2/23/21
export function ServerStatus(
  color_disconnected,
  color_connected,
  HeaderIcon,
  HeaderToolTip
) {
  const [serverToolTip, set_serverToolTip] = useState()
  const [showServerToolTip, set_showServerToolTip] = useState(false)
  const serverToolTip_opacity = useSpring()
  const g = useContext(TherapistContext)

  // :s: Set tool tip hints
  useEffect(() => {
    const available = "Connected to " + g.sessionName
    if (g.servAvailable) set_serverToolTip(available)
    if (!g.servAvailable)
      set_serverToolTip("Server connection loss.  Trying to reconnect...")
  }, [g.servAvailable, g.sessionName])

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.7 }}
    >
      <HeaderIcon
        style={{
          color: g.servAvailable ? color_connected : color_disconnected,
          fontSize: "16px",
        }}
        onHoverStart={() => {
          set_showServerToolTip(true)
          serverToolTip_opacity.set(1)
        }}
        onHoverEnd={() => {
          set_showServerToolTip(false)
          serverToolTip_opacity.set(0)
        }}
      >
        <FaServer />
        <HeaderToolTip
          style={{
            opacity: serverToolTip_opacity,
            display: !showServerToolTip ? "none" : "",
          }}
        >
          {serverToolTip}
        </HeaderToolTip>
      </HeaderIcon>
    </motion.div>
  )
}

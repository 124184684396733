import React, { useState, useEffect, useContext } from "react"
import { AnimatePresence } from "framer-motion"
import { BsFillStopFill } from "react-icons/bs"
import { BsPlayFill } from "react-icons/bs"
import { Icon } from "_components/global/icon"
// import { useCookies } from "react-cookie"
import { Cookies } from "react-cookie"
import { SocketContext } from "_app/common/SocketServer"
import { useMitt } from "react-mitt"

const PlayStop = () => {
  const [isPlaying, set_isPlaying] = useState(false)
  const socket = useContext(SocketContext)
  const { emitter } = useMitt()
  const cookie = new Cookies()
  // const [cookies, set_cookies] = useCookies([
  //   "speed",
  //   "size",
  //   "sessionName",
  //   "repLimit",
  // ])

  useEffect(() => {
    let didCancel = false
    emitter.on("initiateStop", (data) => {
      if (!didCancel) set_isPlaying(false)
    })
    return () => {
      didCancel = true
    }
  }, [emitter])

  function OnClick() {
    if (!isPlaying) {
      set_isPlaying(true)
      console.log(
        "Starting BLS with speed of: " +
          cookie.get("speed") +
          " and size: " +
          cookie.get("size")
      )
      socket.emit("initiatePlay", {
        sessionName: cookie.get("sessionName"), //cookies.sessionName,
        speed: cookie.get("speed"), //cookies.speed,
        size: cookie.get("size"), //cookies.size,
        repLimit: cookie.get("repLimit"), //cookies.repLimit,
      })
    } else {
      set_isPlaying(false)
      socket.emit("initiateStop", { sessionName: cookie.get("sessionName") })
    }
  }

  return (
    <AnimatePresence>
      {!isPlaying && <>{Icon(BsPlayFill, OnClick, "31px", 0.5)}</>}
      {isPlaying && <>{Icon(BsFillStopFill, OnClick, "31px")}</>}
    </AnimatePresence>
  )
}

export default PlayStop

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Wrapper, Answer, Title, List, ListItem, Highlight } from "./styles"
import styled from "styled-components"

const VideoWindowOpened = (props) => {
  return (
    <Wrapper>
      <div>
        <div>
          <Title multiplier=".9">
            Video conferencing has opened in a new window.
          </Title>
        </div>
        <Answer>
          Please arrange this window and the newly opened video conferencing
          window so that you can see both at the same time.
        </Answer>
        <ImgWrapper>
          <StaticImage src="VideoWindowOpened.png" alt="Arrange windows" />
        </ImgWrapper>
        <Answer>
          If a new browser window did not open, please disable pop-up blockers
          and try again. If the window still did not open, manually open a new
          browser and copy/paste the link below into the address bar.
        </Answer>

        <Answer>
          <Highlight>{props.therapistUrl}</Highlight>
        </Answer>

        <List>
          <ListItem>
            We have also sent the conference settings to your client and
            instructed them to do the same. Please give them a moment to
            connect.
          </ListItem>
          <ListItem>
            If you accidentally close your video conferencing browser window,
            you can open it again at any time from the left menu.
          </ListItem>
        </List>
      </div>
    </Wrapper>
  )
}

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 15px 0;
`

export default VideoWindowOpened

import React, { useState, useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
// import { NewCookieExpiryDate } from "_data/global_functions.js"
import { useCookies } from "react-cookie"
import { SetCookie } from "_app/common/utils/SetCookie"
import { useForm } from "react-hook-form"
import {
  Wrapper,
  Title,
  Form,
  FormRow,
  FormInput,
  Answer,
  Highlight,
  Comment,
  ButtonType1,
} from "../styles"

const AnyMeeting = () => {
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "_conn_anyMeeting",
  ])
  const setCookie = SetCookie(set_cookie)
  const [isError, set_isError] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      userInput: cookie._conn_anyMeeting,
    },
  })

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  function SubmitForm(data) {
    try {
      const accountName = data.userInput
      const url = "https://anymeeting.com/" + accountName
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", url)
      setCookie("_conn_therapistUrl", url)
      setCookie("_conn_anyMeeting", data.userInput)
      setCookie("provider", "anyMeeting")
      setCookie("integratedProvider", "false")
    } catch (err) {
      set_isError(true)
    }
  }

  return (
    <Wrapper>
      <div>
        <>
          <Title multiplier=".9">Any Meeting</Title>
          <Answer style={{ marginTop: "3px" }}>
            Please enter your <Highlight>Any Meeting</Highlight> account name
            below. We do not verify entries, so please check for accuracy before
            submitting.
          </Answer>
          <Comment>
            To avoid confusion, do not send your client a VSee link or account
            name. We handle the video conference connection for them once they
            arrive to the ActiveEMDR session.
          </Comment>
        </>
      </div>

      <Form onSubmit={handleSubmit(SubmitForm)}>
        <FormRow>
          <FormInput name="userInput" type="text" ref={register} required />
          <ButtonType1
            style={{
              perspective: "500px",
            }}
            initial={{ rotate: 0.01, scale: 1.01 }}
            transition={spring}
            whileHover={{
              scale: 1.05,
              rotate: 0.02,
              backgroundColor: "#984FB8",
            }}
            whileTap={{ scale: 0.99 }}
          >
            <span>Set Account Name</span>
          </ButtonType1>
        </FormRow>
        {isError && (
          <Answer style={{ marginTop: "15px" }}>
            <Highlight>
              There seems to be a problem with the account name that you
              entered. An account name will not contain a link or special
              characters.
            </Highlight>
          </Answer>
        )}
      </Form>
    </Wrapper>
  )
}

export default AnyMeeting

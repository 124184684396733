import React, { useState, useEffect } from "react"
import { BiBell } from "react-icons/bi"
import { BiBellOff } from "react-icons/bi"
import { Icon } from "_components/global/icon"
import { motion, AnimatePresence } from "framer-motion"
import { useMitt } from "react-mitt"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

const LocalVolume = () => {
  const [isMuted, set_isMuted] = useState(false)
  const [hintText, set_hintText] = useState("")
  const [showHint, set_showHint] = useState(false)
  const { emitter } = useMitt()

  useEffect(() => {
    if (isMobile) {
      set_hintText("Not available on mobile")
      set_isMuted(true)
    }
  }, [])

  function OnClick() {
    if (!isMuted) {
      if (!isMobile) {
        set_hintText("Local audio disabled")
        emitter.emit("isMutedLocal", { val: true })
        set_isMuted(true)
      }
      set_showHint(true)
    } else {
      if (!isMobile) {
        set_hintText("Local audio enabled")
        set_isMuted(false)
        emitter.emit("isMutedLocal", { val: false })
      }
      set_showHint(true)
    }
  }

  function OnComplete() {
    set_showHint(false)
  }

  return (
    <Content>
      <AnimatePresence>
        {!isMuted && <>{Icon(BiBell, OnClick, "24px", 0.65)}</>}
        {isMuted && <>{Icon(BiBellOff, OnClick, "24px")}</>}
      </AnimatePresence>
      {showHint && (
        <AnimatePresence>
          <Hint
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0, 1, 1, 1, 0], y: [-65, -75] }}
            transition={{ duration: 2 }}
            onAnimationComplete={OnComplete}
          >
            {hintText}
          </Hint>
        </AnimatePresence>
      )}
    </Content>
  )
}

const Content = styled.div`
  /* position: relative; */
`

const Hint = styled(motion.div)`
  position: absolute;
  z-index: 20000;
  width: 160px;
  background-color: #d1dce6;
  text-align: center;
  margin-left: -68px;
  color: #245eab;
  padding: 5px 0 5px 0;
  border-radius: 6px;
  font-size: 12px;
  font-family: montserrat;
  font-weight: 600;
  @media only screen and (max-height: 500px) {
    /* top: 50px; */
  }
`

export default LocalVolume

import React, { useState, useEffect, useRef, useContext } from "react"
import { BiTachometer } from "react-icons/bi"
import { Icon } from "_components/global/icon"
import { NewCookieExpiryDate } from "_data/global_functions.js"
// import { ScaleRange } from "_data/global_functions"
import { useCookies } from "react-cookie"
import { useMitt } from "react-mitt"
import Slider from "./Slider"
import { SocketContext } from "_app/common/SocketServer"

const BallSpeed = (props) => {
  const [cookies, set_cookies] = useCookies(["speedVis", "speed"])
  const [showSlider, setShowSlider] = useState(false)
  const { emitter } = useMitt()
  const initialValue = useRef(cookies.speedVis)
  const prevValue = useRef(0)
  const socket = useContext(SocketContext)

  useEffect(() => {
    if (props.showSpeedSlider) setShowSlider(true)
  }, [props.showSpeedSlider])

  function AdjustBallSpeed() {
    props.CB_showSlider("speed")
  }

  // :s: This callback function is called on confirmed or canceled value and every
  // :s: tick of the slider adjustment.
  function OnCallback(isFinal, controlOutput) {
    // const adjValue = ScaleRange(controlOutput, 1, 240, 30, 0.125)
    const adjValue = 60 / controlOutput / 2

    if (prevValue.current !== adjValue) {
      emitter.emit("ballSpeed", { speed: adjValue })
      prevValue.current = adjValue
    }

    if (isFinal) {
      socket.emit("confirmedSpeed", {
        speed: adjValue,
        sessionName: cookies.sessionName,
      })

      set_cookies("speedVis", controlOutput, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })

      set_cookies("speed", adjValue, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })

      setShowSlider(false)
      props.set_showSpeedSlider(false)
      props.set_showSingleControl(false)
    }
  }

  return (
    <>
      {!showSlider && <>{Icon(BiTachometer, AdjustBallSpeed, "29px", 0.55)}</>}

      {showSlider && (
        <>
          <Slider
            label="Speed (RPM)"
            min={4}
            max={120}
            initialValue={initialValue.current}
            callback={OnCallback}
          />
        </>
      )}
    </>
  )
}

export default BallSpeed

import React from "react"
import { Wrapper, Title, Highlight, Answer } from "./styles"

const VideoNotReady = () => {
  return (
    <Wrapper>
      <div style={{ maxWidth: "650px" }}>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Please select a conference provider.</Title>

          <Answer>
            Before we can open the video conferencing window for you and your
            client, you must provide us with more information. Please click{" "}
            <Highlight>Select Video Provider</Highlight> from the menu on
            the left.
          </Answer>
        </header>
      </div>
    </Wrapper>
  )
}

export default VideoNotReady

import React, { useState, useContext } from "react"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import { useCookies } from "react-cookie"
import { SetCookie } from "_app/common/utils/SetCookie"
import { useForm } from "react-hook-form"
import {
  Wrapper,
  Title,
  Form,
  FormRow,
  FormInput,
  Answer,
  Highlight,
  Comment,
  ButtonType1,
} from "../styles"

const Zoom = () => {
  const g = useContext(TherapistContext)
  const [cookie, set_cookie] = useCookies([
    "_conn_clientUrl",
    "_conn_therapistUrl",
    "_conn_zoomInvite",
  ])
  const setCookie = SetCookie(set_cookie)
  const [isError, set_isError] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      userInput: cookie._conn_zoomInvite,
    },
  })

  const spring = {
    type: "spring",
    stiffness: 530,
    damping: 60,
    mass: 6,
    restDelta: 0.001,
    restSpeed: 0.001,
  }

  function SubmitForm(data) {
    try {
      const fromUser = GetValidUrl(data.userInput)
      const step1 = fromUser.match(/\/j\/.+?\b/g)
      const zoomId = step1.toString().replace("/j/", "")
      const clientUrl = fromUser.replace("/j/", "/wc/join/")
      const therapistUrl = "https://zoom.us/wc/" + zoomId + "/start"
      g.set_vidWinOpen(false)
      g.set_showPage("ProviderConfirmed")
      setCookie("_conn_clientUrl", clientUrl)
      setCookie("_conn_therapistUrl", therapistUrl)
      setCookie("_conn_zoomInvite", fromUser)
      setCookie("provider", "zoom")
      setCookie("integratedProvider", "false")
    } catch (err) {
      set_isError(true)
    }
  }

  // :s: Get valid url
  function GetValidUrl(url) {
    let newUrl = window.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, "")

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}`
    }

    return newUrl
  }

  return (
    <Wrapper>
      <div>
        <>
          <Title multiplier=".9">Zoom</Title>
          <Answer style={{ marginTop: "3px" }}>
            Please enter your Zoom invite link below. We do not verify entries,
            please check for accuracy before proceeding. We strongly suggest you
            create a <Highlight>Recurring Meeting</Highlight> inside of your
            Zoom dashboard. However, you can also use an instant Zoom meeting
            link.
          </Answer>
          <Comment>
            To avoid confusion, do not send the Zoom invite link directly to
            your client. We handle the video conference connection for them once
            they arrive to the ActiveEMDR session.
          </Comment>
        </>
      </div>

      <Form onSubmit={handleSubmit(SubmitForm)}>
        <FormRow>
          <FormInput name="userInput" type="text" ref={register} required />
          <ButtonType1
            style={{
              perspective: "500px",
            }}
            initial={{ rotate: 0.01, scale: 1.01 }}
            transition={spring}
            whileHover={{
              scale: 1.05,
              rotate: 0.02,
              backgroundColor: "#984FB8",
            }}
            whileTap={{ scale: 0.99 }}
          >
            <span>Set Zoom Invite Link</span>
          </ButtonType1>
        </FormRow>
        {isError && (
          <Answer style={{ marginTop: "15px" }}>
            <Highlight>
              There seems to be a problem with the Invitation Link that you
              entered. Copy and paste the full invitation URL from your Zoom
              dashboard for this meeting room. If you continue to have issues,
              please contact support from the menu on the left.
            </Highlight>
          </Answer>
        )}
      </Form>
    </Wrapper>
  )
}

export default Zoom

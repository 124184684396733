import React, { useState, useContext } from "react"
import { FormInput } from "_data/global_styles"
import { HeaderContainer } from "_data/global_styles"
import { motion } from "framer-motion"
import { NewCookieExpiryDate } from "_data/global_functions.js"
import { Paragraph } from "_data/global_styles"
import { SocketContext } from "_app/common/SocketServer"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import ConnectButton from "_app/common/ConnectButton"
import Footer from "_components/nav/footer/_footer"
import Header from "_components/nav/header/_header"
import PageHeadline from "_components/misc/pageHeadline"
import { Screen, Content, Form, Row, Highlight } from "_app/common/styles"
import styled from "styled-components"
import Spinner from "_components/global/spinner"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"

const InitiateSession = () => {
  const [cookie, set_cookie] = useCookies(["sessionName"])
  const [buttonText, set_buttonText] = useState("Start Session")
  const socket = useContext(SocketContext)
  const g = useContext(TherapistContext)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      sessionName: cookie.sessionName,
    },
  })

  const StartRemoteSession_onClick = (data) => {
    set_buttonText("Connecting")
    g.set_sessionName(data.sessionName)
    set_cookie("sessionName", data.sessionName, {
      path: "/",
      expires: NewCookieExpiryDate(360),
    })

    socket.emit("join", {
      sessionName: data.sessionName,
      from: "therapist",
      to: "server",
    })
  }

  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            {!g.servAvailable && (
              <PageHeadline copy="Starting the communications server." />
            )}

            {g.servAvailable && (
              <PageHeadline copy="Start an online session with your client." />
            )}

            <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
              {!g.servAvailable && (
                <>
                  <Paragraph>
                    We are starting the communication server. This can take up
                    to 30 seconds. Please be patient and do not leave this page,
                    it will refresh automatically.{" "}
                    <Highlight>
                      Did you arrive at this page by using your browsers back or
                      forward button?
                    </Highlight>{" "}
                    If so, reload this page now.
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                    <Message>Starting communications server...</Message>
                  </div>
                </>
              )}

              {/* -> Show paragraph when server is available */}
              {g.servAvailable && (
                <>
                  <Paragraph>
                    Please enter your <Highlight>Session Password</Highlight>{" "}
                    below. Don't have one yet? No worries, just make one up. It
                    can be a single word, a phrase, a sequence of numbers, or
                    any combination of these.
                  </Paragraph>
                  <Paragraph>
                    A Session Password is simply our way of connecting you with
                    your client. Although not necessary, you are encouraged to
                    use a different one for each client. Just remember that the
                    both of you must use the exact same Session Password.
                    Spelling, punctuation, and even spaces matter.
                  </Paragraph>
                </>
              )}
            </div>

            {/* -> Show form when server is available */}
            {g.servAvailable && (
              <Form onSubmit={handleSubmit(StartRemoteSession_onClick)}>
                <Row>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    <FormInput
                      name="sessionName"
                      type="text"
                      ref={register}
                      required
                    />
                  </div>
                  <ConnectButton buttonText={buttonText} />
                </Row>
              </Form>
            )}
          </motion.div>
        </Content>
      </Screen>
      <Footer />
    </>
  )
}

const Message = styled(motion.div)`
  font-family: montserrat;
  font-size: 16px;
  font-weight: 600;
  color: #303c6c;
`

export default InitiateSession

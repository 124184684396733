import React, { useContext } from "react"
import { AiFillCode } from "react-icons/ai"
import { FaQuestionCircle } from "react-icons/fa"
import { GiVibratingBall } from "react-icons/gi"
import { RiDashboardFill } from "react-icons/ri"
import { ImExit } from "react-icons/im"



import { CgArrowsShrinkH } from "react-icons/cg"
import { IoIosHelpBuoy } from "react-icons/io"
import { BsFillPeopleFill } from "react-icons/bs"
import { FaCheckSquare } from "react-icons/fa"
import { SiYoutube } from "react-icons/si"
import MenuItem from "_app/therapist/leftMenu/MenuItem"
import styled from "styled-components"
import { FaStar } from "react-icons/fa"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"

function Item(label, emitterName, icon, props, isWindowOpen) {
  return (
    <MenuItem
      menu={emitterName}
      label={label}
      icon={icon}
      smallScreen={props.smallScreen}
      set_smallScreen={props.set_smallScreen}
      leftEdgeWidth_small={props.leftEdgeWidth_small}
      isWindowOpen={isWindowOpen}
    />
  )
}

const LeftMenu = (props) => {
  const g = useContext(TherapistContext)

  const menuIcons = [
    <GiVibratingBall />,
    <BsFillPeopleFill />,
    <ImExit />,
    <FaQuestionCircle />,
    <AiFillCode />,
    <IoIosHelpBuoy />,
    <RiDashboardFill />,
    <FaStar />,
    <FaCheckSquare />,
    <SiYoutube />,
    <CgArrowsShrinkH />
  ]

  return (
    <Column>
      <Menu style={{ fontSize: props.smallScreen ? ".8em" : ".7em" }}>
        <Spacer />
        {Item("Select Video Provider", "selectProvider", menuIcons[8], props)}
        {Item(g.leftMenuVidText, "toggleVideo", menuIcons[1], props, true)}
        {Item("Begin BLS", "emdr", menuIcons[0], props)}
        <Spacer />
        {Item("Latest Release News", "welcome", menuIcons[7], props)}
        {Item("Quick Start Guide", "quickStart", menuIcons[6], props)}
        {Item("Tutorial Videos", "videos", menuIcons[9], props)}
        {Item("Application FAQ", "faq", menuIcons[3], props)}
        {Item("Changelog", "changeLog", menuIcons[4], props)}
        {Item("Support", "support", menuIcons[5], props)}
      </Menu>
      <Menu
        style={{
          fontSize: props.smallScreen ? ".8em" : ".7em",
        }}
      >
        {Item("Toggle Menu", "toggleMenuSize", menuIcons[10], props)}
        {Item("End Session", "endSession", menuIcons[2], props)}
        <Spacer />
      </Menu>
    </Column>
  )
}

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Menu = styled.div`
  font-family: montserrat;
  font-size: 0.7em;
  font-weight: 600;
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`

const Spacer = styled.div`
  margin-top: 25px;
  @media only screen and (max-height: 350px) {
    margin-top: 0px;
  }
`

export default LeftMenu

import React, { useState, useContext } from "react"
import { ClientStatus } from "./ClientStatus"
import { motion } from "framer-motion"
import { ServerStatus } from "./ServerStatus"
import { CreateSessionLink } from "./CreateSessionLink"
import { useCookies } from "react-cookie"
import { UserName } from "./UserName"
import Logo from "_components/misc/logo"
import { TherapistContext } from "_app/therapist/TherapistGlobalVars"
import styled from "styled-components"

const color_waiting = "#97b3c1"
const color_connected = "#3296e5"
const color_disconnected = "#dc2b1e"

const Header = (props) => {
  const [cookies, set_cookies] = useCookies(["therapistName"])
  const [name, set_name] = useState(cookies.therapistName)
  const g = useContext(TherapistContext)

  return (
    <Wrapper
      style={{
        width: props.smallScreen ? "96%" : "97%",
      }}
    >
      <LogoWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 3, ease: "easeOut" }}
        style={{
          userSelect: "none",
          minWidth: props.smallScreen ? "150px" : "210px",
        }}
      >
        <Logo multiplier={props.smallScreen ? ".65" : ".7"} />
      </LogoWrapper>

      <Content>
        {/* Finished the email component at a later time. */}
        {/* {Email(props, HeaderButton, ModalWrapper, Modal, CopyWrapper, Copy)} */}

        {CreateSessionLink(
          props,
          HeaderButton,
          ModalWrapper,
          Modal,
          CopyWrapper,
          Copy,
          HeaderToolTip,
          HeaderIcon
        )}

        {ClientStatus(
          g.clientConnected,
          g.servAvailable,
          color_disconnected,
          color_waiting,
          color_connected,
          HeaderIcon,
          HeaderToolTip
        )}

        {ServerStatus(
          color_disconnected,
          color_connected,
          HeaderIcon,
          HeaderToolTip
        )}

        <Name
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          {name}
        </Name>

        {UserName(
          props,
          name,
          set_name,
          set_cookies,
          HeaderButton,
          ModalWrapper,
          Modal,
          CopyWrapper,
          Copy,
          HeaderToolTip
        )}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  width: 95%;
`

const Content = styled.div`
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LogoWrapper = styled(motion.div)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: initial;
`

const Name = styled(motion.div)`
  font-family: montserrat;
  font-size: 11px;
  font-weight: 600;
  text-align: right;
  color: #878c9c;
  margin-left: 30px;
  @media only screen and (max-width: 500px) {
    display: none;
  }
`

const ModalWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  width: 300px;
  height: 120px;
  right: 0;
  top: 60px;
  background-color: white;
`

const Modal = styled.div`
  position: relative;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 0 0 0 10px;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
`

const CopyWrapper = styled.div`
  flex: 1 1 0;
  padding: 0 10px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Copy = styled.div`
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6em;
  padding: 0;
  margin: 0;
`

const HeaderButton = styled(motion.button)`
  height: 35px;
  padding: 0 10px 0 10px;
  font-family: montserrat;
  margin-left: 15px;
  font-size: 0.7em;
  cursor: pointer;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 7px;
  text-align: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4), 12px 12px 25px rgba(0, 0, 0, 0.15);
  border: none;
  outline: none;
`

const HeaderToolTip = styled(motion.div)`
  position: absolute;
  z-index: 10000;
  top: 45px;
  font-family: montserrat;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  background-color: #d1dce6;
  color: #245eab;
  padding: 5px 15px 5px 15px;
  margin-right: 10px;
  text-align: center;
  border-radius: 3px;
`

const HeaderIcon = styled(motion.div)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 2px;
`

export default Header

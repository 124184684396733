import { NewCookieExpiryDate } from "_data/global_functions.js"

export function SetCookie(set_cookie) {
  return (cookieName, val) => {
    set_cookie(cookieName, val, {
      path: "/",
      expires: NewCookieExpiryDate(360),
    })
  }
}

import React from "react"
import { FaLink } from "react-icons/fa"
import {
  Wrapper,
  Title,
  Highlight,
  Answer,
  Comment,
  ParagraphGroup,
  List,
  ListItem,
} from "./styles"

const QuickStart = () => {
  return (
    <Wrapper>
      <div>
        <header style={{ marginLeft: "0px", color: "#303c6c" }}>
          <Title multiplier=".9">Quick Start Guide</Title>
          <ParagraphGroup>
            <Answer>
              There are just a couple of steps you'll need to take before you
              begin BLS. They can be done before or after your client has
              connected to the session.
            </Answer>
            <Comment>
              Your preferences will be remembered and you will not need go
              through these steps again. You may also change your video
              conference selection at any time, even while your client is
              connected.
              {/* If you
              do not plan on using video conferencing, you can either select{" "}
              <Highlight>No Video</Highlight> from the provider list or never
              click on the <Highlight>Show Conferencing</Highlight> menu item. */}
            </Comment>
          </ParagraphGroup>
        </header>

        <div style={{ margin: "40px 0 0 0px" }}>
          <Title multiplier=".7" style={{ color: "#1c78c1" }}>
            The short version...
          </Title>
          <ParagraphGroup>
            <Answer>
              Follow these three easy steps to get started with ActiveEMDR. All
              of this is accessible from the menu on the left.
            </Answer>
            <List>
              <ListItem>Select Video Provider</ListItem>
              <ListItem>Show Conferencing</ListItem>
              <ListItem>Begin BLS Processing</ListItem>
            </List>
          </ParagraphGroup>
        </div>

        <div style={{ margin: "60px 0 0 0px" }}>
          <Title multiplier=".7" style={{ color: "#1c78c1" }}>
            The long version...
          </Title>

          <div style={{ margin: "20px 0 0 20px" }}>
            <Highlight>Select Video Provider</Highlight>

            <Answer>
              On the left menu, please click{" "}
              <Highlight>Select Video Provider</Highlight> and follow the
              prompts. We have two different types of video conference
              providers. Those that can be embedded right into our app, and
              those that require a second web browser. Doxy.me is currently the
              only provider that can be embedded into our app. Therefore, it is
              our preferred provider for its simplicity and user experience it
              will provide to you and your client.
            </Answer>
            <Answer>
              The other video conference providers that we support will open in
              a second web browser window. The two of you will need to resize
              and reposition the windows so that you can see both at the same
              time. We make this process easier by automatically opening the
              windows and sending everyone to the correct web address.
            </Answer>
            <Comment>
              If you wish to handle video conferencing yourself or do not want
              to use video conferencing at all, please select{" "}
              <Highlight>No Video</Highlight> as your provider.
            </Comment>
            <Answer>
              If you'd like to change your preferences in the future, you can go
              through this process as many times as you need. Please also be
              aware that if you delete your browser cookies, you will have to
              complete this step again.
            </Answer>

            <div style={{ marginTop: "30px" }}>
              <Highlight>Show Video</Highlight>
           
                <Answer>
                  The <Highlight>Show Conferencing</Highlight> menu item will
                  show the video conferencing (if you've made a selection) for
                  both you and your client. If you are using an embedded
                  provider, it will show directly in the app. Otherwise a new
                  browser window will open.
                </Answer>

                <Comment>
                  For those conference providers that are not embedded, please
                  arrange the two windows so that you can see them both at the
                  same time. Ask your client to do the same, making sure that
                  they adjust the BLS browser to be as wide as possible.
                </Comment>

                <Comment>
                  Depending on the video conferencing provider that you have
                  chosen, you and your client may need to enter a conference
                  password or follow additional prompts in the new window. We
                  automate this process as much as possible for your client.
                </Comment>
           
            </div>

            <div style={{ marginTop: "30px" }}>
              <Highlight>Begin BLS Processing</Highlight>
            
                <Answer>
                  When you click <Highlight>BLS</Highlight> from the left menu,
                  you will see the ball and controls for EMDR processing. Keep
                  in mind that the ball size is in proportion to the window
                  size. It is always best to ask your client how big the ball is
                  on their screen. Speeds are based on time and not window size,
                  so it should be identical for you and your client.
                </Answer>
                <Comment>
                  While you will instantly see the ball change size when making
                  adjustments, your client will not see the change until you
                  confirm it by clicking the checkmark. The same goes for the
                  ball speed. Please keep in mind, once a speed change has been
                  confirmed, both you and your clients BLS will stop moving.
                  This is by design and is necessary to keep you and your client
                  in sync.
                </Comment>
           
            </div>

            <div style={{ marginTop: "30px" }}>
              <Highlight>When you're ready to use with your clients.</Highlight>
           
                <Answer>
                  Because we currently have both the old and new version of our
                  tools available at once. You must make sure your client is
                  using version 1 of our tools. You can easily ensure this
                  happens by creating a{" "}
                  <Highlight>Direct Session Link</Highlight>. Otherwise the
                  client must remember to click on the Version 1.0 link.
                </Answer>
                <Answer>
                  A <Highlight>Direct Session Link</Highlight> is created from
                  the
                  <FaLink style={{ marginLeft: "5px" }} /> icon at the upper
                  right of this screen. A link will be generated that will
                  directly connect your client to the current session you are
                  now logged in as. Email this link to your client so that they
                  will be connected directly to the session without having to
                  enter a Session Password.
                </Answer>

                <Comment>
                  When you decide upon a Session Password, you want to make them
                  complicated enough that it cannot be guessed, but not so
                  complicated that it makes it difficult for your client.
                  Spelling and punctuation matter, even a missed space will keep
                  you from being connected to your client. To make the process
                  easier for your client, you should be familiar with the{" "}
                  <FaLink style={{ marginLeft: "5px" }} /> icon at the upper
                  right of this screen. These{" "}
                  <Highlight>Direct Session Links</Highlight> will make your
                  clients life easier.
                </Comment>
                <Comment>
                  We call this a <Highlight>Direct Session Link</Highlight>, and
                  the link can be used by your client each time the two of you
                  meet on ActiveEMDR. The Direct Session Link contains the
                  Session Password and won't require your client to enter it
                  themselves. Please use a different Session Password for each
                  client.
                </Comment>
          
            </div>

            {/* <Question>Step 2 (Have your client join the session)</Question>
            <ParagraphGroup>
              <Answer>
                Your client should visit the ActiveEMDR website and click the{" "}
                <Highlight>Enter Session</Highlight> button. They will need to
                enter the same Session Password that you are using. When our
                session server sees two people using the same password, it
                connects them together.
              </Answer>
              <Answer>
                We strongly encourage you to create a Direct Session Link (DSL)
                for your client. If you email your client a DSL before your
                session begins, your client can simply click on the link and
                they will be connected directly into the session without having
                to enter a Session Password.
              </Answer>

              <Comment>
                Session Passwords can be tricky. You want them complicated
                enough that it cannot be guessed, but not so complicated that it
                is easy for your client to enter the wrong password. Spelling
                and punctuation matter, even a missed space will keep you from
                being connected to your client. To make the process easier for
                your client, you can click the
                <FaLink style={{ marginLeft: "5px" }} /> icon on the upper right
                of this screen. A link will be provided that will directly
                connect your client to you through the session server. They will
                not need to enter the Session Password manually.
              </Comment>
              <Comment>
                We call this a <Highlight>Direct Session Link</Highlight>, and
                the link can be used by your client each time the two of you
                meet on ActiveEMDR. The Direct Session Link contains the Session
                Password and won't require your client to enter it themselves.
                Please use a different Session Password for each client.
              </Comment>
            </ParagraphGroup> */}

            {/* <Question>Step 3 (Show Video)</Question>
            <ParagraphGroup>
              <Answer>
                From the left menu, click <Highlight>Show Video</Highlight> to
                begin video conferencing with your client. If you are using our
                recommended providers, the video will be integrated without our
                app. If not, a new browser will open that will connect you to
                your video conferencing provider. This will also trigger the
                same thing to happen on your client's screen when they are
                ready.
              </Answer>

              <Comment>
                When the new browser window opens, please arrange the two so
                that you can see them both at the same time. Ask your client to
                do the same, making sure that they adjust the BLS browser as
                wide as possible.
              </Comment>

              <Comment>
                Depending on the video conferencing provider that you have
                chosen, you and your client may need to enter a conference
                password, room name, or follow additional prompts. We will
                automate this process as much as possible for your client.
              </Comment>
            </ParagraphGroup> */}
            {/* <Question>Step 4 (Begin processing)</Question>
            <ParagraphGroup>
              <Answer>
                When you click <Highlight>BLS</Highlight> from the left menu,
                you will see the ball and controls for processing. Set the
                repititions, speed, and size as you see fit. Keep in mind the
                ball size is in proportion to the window size. It is always best
                to ask your client how big the ball is on their screen. Speeds
                are based on time and not window size, so it should be identical
                for you and your client.
              </Answer>
              <Comment>
                While you will instantly see the ball change size when making
                adjustments, your client will not see the change until you
                confirm it by clicking the checkmark. The same goes for the ball
                speed. Please keep in mind, once a speed change has been
                confirmed, both you and your clients BLS will stop moving. This
                is by design and is necessary to keep you and your client in
                sync.
              </Comment>
            </ParagraphGroup> */}

            <div style={{ marginTop: "30px" }}>
              <Highlight>Try it with a friend!</Highlight>
         
                <Answer>
                  Even if you've been using ActiveEMDR for a while, please try
                  v1.0 out with a friend or on two separate computers before
                  working with a client for the first time.
                </Answer>
          
            </div>

            <div style={{ marginTop: "30px" }}>
              <Highlight>
                Use ActiveEMDR for all teletherapy sessions.
              </Highlight>
         
                <Answer>
                  While testing v1.0 of our tools, we've been told that some
                  therapists have been using ActiveEMDR to connect with their
                  clients, even when they do not plan on using EMDR at all. We
                  welcome everyone to use ActiveEMDR in this way!
                </Answer>
                <Comment>
                  This can come in handy if you decide to start impromptu EMDR
                  processing half way through a teletherapy session.
                </Comment>
                <Answer addBottomMargin>
                  Using ActiveEMDR in this manner also provides a standardized
                  way to connect with your clients, no matter what video
                  conference provider you decide to use that day. As a bonus, if
                  your provider has an outage, you can easily switch temporarily
                  to a new one and your client won't have to be notified ahead
                  of time.
                </Answer>
           
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default QuickStart
